import numeral from "numeral";

export const formatNumber = (value, numberOfDecimals) => {
    let result;
    let temp = parseFloat(value);
    let leftSide;
    let rightSide;

    temp = temp.toFixed(numberOfDecimals);

    result = temp.toString().replace('.', ',');
    leftSide = result.split(',')[0];
    rightSide = result.split(',')[1];

    return numberOfDecimals ? `${leftSide.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')},${rightSide}` : leftSide.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}

export const formatNumber2 = (value, decimalPlaces = undefined, isDecimalFormatting = false) =>  {
    if (value !== null) {
      if (decimalPlaces === undefined || decimalPlaces <= 0) {
  
        if(isDecimalFormatting === true) {
        //if number is decimal, format it with decimal places
        let nOd = value.toString().split(".")[1].length;
        let nOdFormat = "0,0.";
        for (let i = 0; i < nOd; i++) {
          nOdFormat += '0';
        }
        return numeral(value).format(nOdFormat);
      }
  
         return numeral(value).format();
      }
  
      let format = "0,0.";
      for (let i = 0; i < decimalPlaces; i++) {
        format += '0';
      }
      return numeral(value).format(format);
    }
  
    return undefined;
  }