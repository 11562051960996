export const formatNumber = (value, numberOfDecimals) => {
    let result;
    let temp = parseFloat(value);
    let leftSide;
    let rightSide;

    temp = temp.toFixed(numberOfDecimals);

    result = temp.toString().replace('.', ',');
    leftSide = result.split(',')[0];
    rightSide = result.split(',')[1];

    return numberOfDecimals ? `${leftSide.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')},${rightSide}` : leftSide.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}