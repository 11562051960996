//Assets
import WindUpIcon from '../../assets/icons/wind-up.svg';
import ClockIcon from '../../assets/icons/clock.png';
import { formatNumber } from '../../helpers/numeral';

const WeatherCard = ({ time, icon, temperature, windSpeed, windDirection }) => {
    return (
        <div
            className={'rounded-xl flex flex-col justify-between h-full bg-em-gray-transparent-65 p-2'}
            style={{
                width: '13%'
            }}
        >
            <div className={'flex justify-center items-center space-x-4'}>
                <img
                    className={'h-5'}
                    src={ClockIcon}
                    alt={'Clock'}
                />
                <h1 className={'text-center text-em-white-500 text-2xl'}>
                    {time}
                </h1>
            </div>

            <div className={'flex justify-center items-center space-x-2'}>
                <img
                    className={'w-1/3'}
                    src={`/weather/${icon}.svg`}
                    alt={icon}
                    style={{
                        fill: 'white'
                    }}
                />
            </div>
            <div className={'flex justify-center items-center space-x-2'}>
                <h1 className={'text-em-white-500 text-3xl font-bold'}>
                    {formatNumber(temperature, 1)} &#176;C
                </h1>
            </div>

            <div>
                <div className={'flex justify-center items-center space-x-2 mt-1'}>
                    <img
                        className={'h-5'}
                        src={WindUpIcon}
                        alt={'Wind direction'}
                        style={{
                            transform: `rotate(${windDirection}deg)`
                        }}
                    />
                    <h1
                        className={'text-center text-em-white-500 text-2xl font-bold'}
                    >
                        {formatNumber(windSpeed, 1)} <span className={'text-xl font-normal'}>m/s</span>
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default WeatherCard;