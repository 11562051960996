import React from "react";

const CurvedLine = ({ x1, x2, x3, y1, y2, width, fill, side, isLightTheme }) => {

    return (
        <g>
            {side === 'left' &&
                <g>
                    <defs>
                        <linearGradient x1="65%" y1="0%" x2="0%" y2="0%" id={`${fill}`}>
                            <stop offset="0%" stopColor={`#${fill}`} stopOpacity="0.95" />
                            <stop offset="95%" stopColor={`#${fill}`} stopOpacity="0.3"/>
                        </linearGradient>
                    </defs>
                    <path
                        d={`M ${x1} ${y1}
                        c 0 0 0 0 ${55} 0
                        c ${55} 0 ${55} ${y2 - y1} ${55 * 2} ${y2 - y1}
                        c 0 0 0 0 ${24} 0
                        `}
                        fill="none" stroke={`url(#${fill})`} strokeWidth={width}/>
                    <path
                        d={`M ${x1} ${y1}
                        c 0 0 0 0 ${55} 0
                        c ${55} 0 ${55} ${y2 - y1} ${55 * 2} ${y2 - y1}
                        c 0 0 0 0 ${24} 0
                        `}
                        className="sankey-dots"
                        strokeLinecap="round" strokeDasharray="0.2, 5" strokeDashoffset="3" fill="none" stroke="#fafafa" />

                    <polygon
                        points={`${x1 - 1},${y1 + width / 2} ${x1 - 1},${y1 - width / 2} ${x1},${y1 - width / 2} ${x1 + 4},${y1} ${x1},${y1 + width / 2}`}
                        fill={isLightTheme ? '#fafafa' : '#1A1F25'} />
                    <polygon
                        points={`${x3 - 4},${y2 - width / 2} ${x3 - 4},${y2 - width / 2 - 1} ${x3 + 1},${y2 - width / 2 - 1} ${x3 + 1},${y2 + width / 2 + 1} ${x3 - 4},${y2 + width / 2 + 1} ${x3 - 4},${y2 + width / 2} ${x3},${y2}`}
                        fill={isLightTheme ? '#fafafa' : '#1A1F25'} />

                </g>
            }
            {side !== 'left' &&
                <g>
                    <defs>
                        <linearGradient x1="0%" y1="0%" x2="55%" y2="0%" id={`${fill}`}>
                            <stop offset="0%" stopColor={`#${fill}`} stopOpacity="0.95" />
                            <stop offset="95%" stopColor={`#${fill}`} stopOpacity="0.3"/>
                        </linearGradient>
                    </defs>
                    <path
                        d={`M ${x1} ${y1}
                        c 0 0 0 0 ${0} 0
                        c ${55} 0 ${55} ${y2 - y1} ${55 * 2} ${y2 - y1}
                        c 0 0 0 0 ${74} 0
                        `}
                        fill="none" stroke={`url(#${fill})`} strokeWidth={width}/>
                    <path
                        d={`M ${x1} ${y1}
                        c 0 0 0 0 ${0} 0
                        c ${55} 0 ${55} ${y2 - y1} ${55 * 2} ${y2 - y1}
                        c 0 0 0 0 ${74} 0
                        `}
                        className="sankey-dots"
                        strokeLinecap="round" strokeDasharray="0.2, 5" strokeDashoffset="3" fill="none" stroke="#fafafa" />

                    <polygon
                        points={`${x1 - 1},${y1 + width / 2} ${x1 - 1},${y1 - width / 2} ${x1},${y1 - width / 2} ${x1 + 4},${y1} ${x1},${y1 + width / 2}`}
                        fill={isLightTheme ? '#fafafa' : '#1A1F25'} />
                    <polygon
                        points={`${x3 - 4},${y2 - width / 2} ${x3 - 4},${y2 - width / 2 - 1} ${x3 + 30},${y2 - width / 2 - 1} ${x3 + 30},${y2 + width / 2 + 1} ${x3 + 30},${y2 + width / 2 + 1} ${x3 - 4},${y2 + width / 2} ${x3},${y2}`}
                        fill={isLightTheme ? '#fafafa' : '#1A1F25'} />
                </g>
            }
        </g>
    );
}

export default CurvedLine;
