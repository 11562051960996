import { useEffect, useState } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSliderData } from "../../redux/actions/slideshow";
import { getSlideshow } from "../../services/slideshow";
import BuildingDetails from "../../screens/BuildingDetails";
import WeatherSlide from "../../screens/WeatherSlide";
import MessageSlide from "../../screens/MessageSlide";
import ImageSlide from "../../screens/ImageSlide";
import VideoSlide from "../../screens/VideoSlide";
import UrlSlide from "../../screens/UrlSlide";
import Error500 from "../../components/errors/Error500";
import TopIcon from "../../assets/icons/icon-top.svg";
import PlayIcon from "../../assets/icons/icon-play.svg";
import NextIcon from "../../assets/icons/icon-next.svg";
import PauseIcon from "../../assets/icons/icon-pause.svg";
import BottomIcon from "../../assets/icons/icon-bottom.svg";
import PreviousIcon from "../../assets/icons/icon-previous.svg";
import { sleep, clearAllSleeps } from "../../helpers/promises";

const viewportCss = { overflow: "hidden" };
const containerCss = { display: "flex", maxHeight: "100vh" };

const Slider = () => {
  const url = useLocation();
  const dispatch = useDispatch();
  const slides = useSelector((state) => state.slideshow.slides);
  const slideshow = useSelector((state) => state.slideshow.slideshow);
  const [isPaused, setIsPaused] = useState(false);
  const [pauseTime, setPauseTime] = useState(undefined);
  const [isError, setIsError] = useState(false);
  const [tempIndex_, setTempIndex_] = useState(false);
  const [updatedDate, setUpdatedDate] = useState(undefined);
  const [newTopForBuildingDetails, setNewTopForBuildingDetails] = useState({
    value: 0,
  });

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
  });

  useEffect(() => {
    document.addEventListener("plugin_web_update_notice", displayNotification);
    return () =>
      document.removeEventListener(
        "plugin_web_update_notice",
        displayNotification
      );
  }, []);

  const displayNotification = () => {
    console.log("Reloading for new version in 5 seconds...");
    window.location.reload(true);
  };

  useEffect(() => {
    if (slides.length > 0 && emblaApi) {
      checkIsYTVideoNext(0).then((res) => {
        delaySlide(res ? res : slides[0].durationInSeconds, 1);
      });
    }
  }, [slides, emblaApi]);

  useEffect(() => {
    if (updatedDate && emblaApi) {
      emblaApi.reInit({
        loop: true,
      });
    }
  }, [updatedDate]);

  useEffect(() => {
    getNewSlideshow();
  }, [dispatch]);

  const scrollNext = () => {
    if (emblaApi.canScrollNext()) {
      emblaApi.scrollNext();
    } else console.log("cannot scroll");
  };

  const scrollPrevious = () => {
    if (emblaApi.canScrollPrev()) emblaApi.scrollPrev();
    else console.log("cannot scroll");
  };

  async function delaySlide(duration, index) {
    let tempIndex = index;
    setTempIndex_(tempIndex - 1);
    if (index >= slides.length) {
      tempIndex = 0;
    }
    let customDelay = await checkIsYTVideoNext(tempIndex);

    await sleep(duration * 1000);
    if (tempIndex === 0) {
      console.log("get new slideshow");
      getNewSlideshow();
      scrollNext();
      setIsPaused(false)
    } else {
      console.log("slide next");
      clearAllSleeps();
      setIsPaused(true);
      scrollNext();
      delaySlide(
        customDelay ? customDelay : slides[tempIndex].durationInSeconds,
        tempIndex + 1
      );
    }
  }

  const pause = () => {
    clearAllSleeps();
    setIsPaused(true);
    setPauseTime(Date.now());
  };

  const play = () => {
    clearAllSleeps();
    setIsPaused(false);
    delaySlide(slides[tempIndex_].durationInSeconds, tempIndex_ + 1);
    setPauseTime(null);
  };

  const getNewSlideshow = () => {
    getSlideshow(url.pathname, url.search.slice(5)).then(async (result) => {
      if (result.status === 200) {
        if (
          result.data &&
          result.data.id &&
          result.data.name &&
          result.data.slides
        ) {
          dispatch(
            setSliderData(
              result.data.id,
              result.data.name,
              result.data.slides,
              result.data,
              result.data.lightTheme,
              result.data.organization
            )
          );
          if (updatedDate !== result.data.updatedDate) {
            setUpdatedDate(result.data.updatedDate);
          }
        }
        setIsError(false);
      } else {
        setIsError(true);
        await sleep(60000);
        getNewSlideshow();
      }
    });
  };

  const slideScrollDown = () => {
    pause();
    let buildingCharts = document.getElementById("buildingCharts" + tempIndex_);
    if (buildingCharts) {
      let numberOfVerticalSliides = Number(
        buildingCharts.getAttribute("number-of-slides")
      );
      console.log(buildingCharts.style.top);
      let oldTop = buildingCharts.style.top;
      let newTop = 0;
      if (oldTop && oldTop !== "0%") {
        newTop = Number(oldTop.substring(1, 4)) + 100;
        if (newTop >= numberOfVerticalSliides * 100) newTop = 0;
      } else {
        newTop = 100;
      }
      buildingCharts.setAttribute("style", "top: -" + newTop + "% !important");
      setNewTopForBuildingDetails({ value: newTop });
    }
  };

  const slideScrollUp = () => {
    pause();
    let buildingCharts = document.getElementById("buildingCharts" + tempIndex_);
    if (buildingCharts) {
      let numberOfVerticalSliides = Number(
        buildingCharts.getAttribute("number-of-slides")
      );
      console.log(buildingCharts.style.top);
      let oldTop = buildingCharts.style.top;
      let newTop = 0;
      if (oldTop && oldTop !== "0%") {
        newTop = Number(oldTop.substring(1, 4)) - 100;
        if (newTop < 0) newTop = numberOfVerticalSliides * 100 - 100;
      } else {
        newTop = numberOfVerticalSliides * 100 - 100;
      }
      buildingCharts.setAttribute("style", "top: -" + newTop + "% !important");
      setNewTopForBuildingDetails({ value: newTop });
    }
  };

  const slideScrollNext = () => {
    pause();
    scrollNext();
    let i = tempIndex_ + 1;
    if (i >= slides.length) setTempIndex_(0);
    else setTempIndex_(i);
  };

  const slideScrollPrevious = () => {
    pause();
    scrollPrevious();
    let i = tempIndex_ - 1;
    console.log(i, "###!! i");
    if (i < 0) setTempIndex_(slides.length - 1);
    else setTempIndex_(i);
  };

  const checkIsYTVideoNext = async (tempIndex) => {
    if (slides[tempIndex] && slides[tempIndex].slideType === "VIDEO") {
      return 99999;
    } else {
      return false;
    }
  };

  const onVideoEnd = () => {
    setIsPaused(false);
    clearAllSleeps();
    scrollNext();
    let i = tempIndex_ + 1;
    if (i >= slides.length) {
      i = 0;
      getNewSlideshow();
    }
    setTempIndex_(i);
    delaySlide(slides[i].durationInSeconds, i + 1);
  };

  return (
    <div>
      {slides.length > 0 ? (
        <div className={slideshow.lightTheme ? "" : "dark"}>
          <div style={viewportCss} ref={emblaRef}>
            <div
              style={containerCss}
              className={pauseTime ? "animationsPaused" : ""}
            >
              {slides.map((slide, index) =>
                slide.slideType === "ENERGY" ? (
                  <div
                    key={`building_details_screen_${index}`}
                    className={"relative w-screen"}
                  >
                    <BuildingDetails
                      slide={slide}
                      slideIndex={index}
                      tempIndex={tempIndex_}
                      isPaused={isPaused}
                      newTopForBuildingDetails={newTopForBuildingDetails}
                      language={slideshow.language}
                      isLightTheme={slideshow.lightTheme}
                    />
                  </div>
                ) : slide.slideType === "MESSAGE" ? (
                  <div
                    key={`message_screen_${index}`}
                    className={"relative w-screen"}
                  >
                    <MessageSlide slide={slide} slideshow={slideshow} />
                  </div>
                ) : slide.slideType === "WEATHER" ? (
                  <div
                    key={`weather_screen_${index}`}
                    className={"relative w-screen"}
                  >
                    <WeatherSlide slide={slide} />
                  </div>
                ) : slide.slideType === "URL" ? (
                  <div
                    key={`url_screen_${index}`}
                    className={"relative w-screen"}
                  >
                    <UrlSlide slide={slide} />
                  </div>
                ) : slide.slideType === "IMAGE" ? (
                  <div
                    key={`image_screen_${index}`}
                    className={"relative w-screen"}
                  >
                    <ImageSlide slide={slide} />
                  </div>
                ) : slide.slideType === "VIDEO" ? (
                  <div
                    key={`image_screen_${index}`}
                    className={"relative w-screen"}
                  >
                    <VideoSlide
                      slide={slide}
                      slideIndex={index}
                      tempIndex={tempIndex_}
                      onVideoEnd={onVideoEnd}
                    />
                  </div>
                ) : (
                  false
                )
              )}
            </div>
          </div>
          <div className="slidermenu">
            <div className="remoteControl">
              <div
                className="button top"
                onClick={function () {
                  slideScrollUp();
                }}
              >
                <i>
                  {" "}
                  <img alt="remote" src={TopIcon}></img>{" "}
                </i>
              </div>
              <div
                className="button right"
                onClick={function () {
                  slideScrollNext();
                }}
              >
                <i>
                  {" "}
                  <img alt="remote" src={NextIcon}></img>{" "}
                </i>
              </div>
              <div
                className="button left"
                onClick={function () {
                  slideScrollPrevious();
                }}
              >
                <i>
                  {" "}
                  <img alt="remote" src={PreviousIcon}></img>{" "}
                </i>
              </div>
              <div
                className="button bottom"
                onClick={function () {
                  slideScrollDown();
                }}
              >
                <i>
                  {" "}
                  <img alt="remote" src={BottomIcon}></img>{" "}
                </i>
              </div>
              <div
                className="center-button"
                onClick={function () {
                  isPaused === false ? pause() : play();
                }}
              >
                <i>
                  {" "}
                  {isPaused ? (
                    <img alt="remote" src={PlayIcon}></img>
                  ) : (
                    <img alt="remote" src={PauseIcon}></img>
                  )}{" "}
                </i>
              </div>
            </div>
          </div>
        </div>
      ) : (
        false
      )}

      {isError && <div>{<Error500 />}</div>}
    </div>
  );
};

export default Slider;
