import NoDataIcon from '../../assets/icons/nodata.svg';

//Translations
import { Trans } from 'react-i18next';

const ChartNoData = () => {

    return (
        <div className={'relative flex w-full'} >
            <div className="nodata text-dark-grayscale-8 dark:text-dark-grayscale-1" style={{marginTop: '20vh'}}>
                <img src={NoDataIcon} alt="nodata" style={{ width: '8vw' }}></img>
                <h2>{<Trans i18nKey={'noData'} >No data</Trans>}</h2>
                <h3 className='text-3x-m'><Trans i18nKey={'checkConfiguration'} >Pelase check you configuration</Trans></h3>
            </div>
        </div>
    )
}

export default ChartNoData;