//Treanslations
import i18n from 'i18next';
import Locize from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

//Config
const i18nextConfig = {
    backend: {
        projectId: '2c0a136a-e84b-4380-bbdd-8c7e033c41a3',
        apiKey: '5e9e1de2-bf79-4fbd-a74e-05772a0a1d12',
        referenceLng: 'en-US'
    },
    lng: 'nb-NO',
    fallbackLng: 'nb-NO',
    ns: [
        'digitalSignage',
        'admin',
        'common',
    ],
    defaultNS: 'digitalSignage',
    saveMissing: false,
    debug: true,
    detection: {
        order: ['queryString', 'cookie'],
        cache: ['cookie']
    },
    interpolation: {
        escapeValue: false
    },
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
        // eslint-disable-next-line
        // console.error('Missing key in translations', lng, ns, key, fallbackValue);
    },
}

i18n.use(Locize).use(LanguageDetector).use(initReactI18next)
    .init(i18nextConfig, error => {
        if (error) {
            throw error;
        }
    })

export default i18n;