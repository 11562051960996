//Components
import VerticalBarChartNew from '../charts/VerticalBarChartNew';
import CalendarIcon from '../../assets/icons/calendar.svg';
import CalendarDarkIcon from '../../assets/icons/calendar-dark.svg';
import EmptyChartWhite from '../../assets/images/empty-chart-white.png';
import EmptyChartBlack from '../../assets/images/empty-chart-black.png';
import i18n from "../../i18n";
import { Trans } from 'react-i18next';
import { formatNumber } from '../../helpers/numeral'
import { useEffect, useState } from 'react';

//Redux
import {useSelector} from 'react-redux';

const HistoricProduction = ({title, subtitle, slideIndex}) => {
    //Redux
    const slide = useSelector(state => state.slideshow.slides[slideIndex]);
    const isLightTheme = useSelector(state => state.slideshow.lightTheme);

    const [current, setCurrent] = useState(undefined);
    const [compare, setCompare] = useState(undefined);
    const [diff, setDiff] = useState(undefined);
    const [historicSolarProduction, setHistoricSolarProduction] = useState(undefined);
    const [currentMonthSolarProduction, setCurrentMonthSolarProduction] = useState(undefined);


    useEffect(() => {
        setHistoricSolarProduction(getHistoricSolarProduction())
        setCurrentMonthSolarProduction(getCurrentMonthSolarProduction())
        setCurrentCopmare()
    }, []);

    const setCurrentCopmare = () => {
        let profiles = slide.slideEnergy2.energyProfile1Y.filter(x => x.resolution === 'p1m')
        let currentTemp = 0;
        let compareTemp = 0;
        let currentYear = new Date().getFullYear() 
        let currentMonth = new Date().getMonth()
        
        for (let i = 0; i < profiles.length; i++) {
                // eslint-disable-next-line no-loop-func
                console.log(profiles[i].data, 'profiles[i].data')
                profiles[i].data.forEach(p => {
                    if (p.timestamp.includes(currentYear)){
                        if (Number(p.timestamp.substring(5, 7)) === 1)
                            compareTemp += 0 //p.value
                        else
                            currentTemp += p.value
                    }
                    else{
                        if (Number(p.timestamp.substring(5, 7)) <= currentMonth+1)
                            compareTemp += p.value
                        else if (Number(p.timestamp.substring(5, 7)) == currentMonth+2)
                            compareTemp += p.value * (new Date().getDate() / 30)
                    }
                })
        }
        if(profiles.length && profiles[0].data.length === 0){
            currentTemp = 0
            let p1d = slide.slideEnergy2.energyProfile1Y.filter(x => x.resolution === 'p1d')
            console.log(p1d, 'p1d')
            p1d.forEach(p => {
                if(p.data.length)
                    p.data.forEach(d => {
                        currentTemp += d.value
                    })
            })
        }
        let cr = getCurrentMonthSolarProduction()
        if (cr){
            cr.forEach(day => {
                currentTemp += day.value;
            })
        }
        setCurrent(currentTemp)
        setCompare(compareTemp)
        if (currentTemp > compareTemp){
            let increasePerc = (currentTemp - compareTemp) / compareTemp * 100
            setDiff(increasePerc)
        } else {
            let decreasePerc = (compareTemp - currentTemp) / compareTemp * -100
            setDiff(decreasePerc)
        }
    }

    //Functions
    const getHistoricSolarProduction = () => {
        if (slide && slide.slideEnergy2 && slide.slideEnergy2.energyProfile1Y) {
            let profiles = slide.slideEnergy2.energyProfile1Y.filter(x => x.resolution === 'p1m')
            let longest = {data: []};
            let id = undefined;

            for (let i = 0; i < profiles.length; i++) {
                if (profiles[i].data.length > longest.data.length) {
                    longest = JSON.parse(JSON.stringify(profiles[i]));
                    id = profiles[i].id;
                }
            }
            if (longest && longest.data) {
                longest.data.forEach(l => {
                    profiles.forEach(p => {
                        if (p.id !== id) {
                            let f = p.data.find(x => x.timestamp === l.timestamp)
                            if (f)
                                l.value += f.value
                        }
                    })

                })
                return longest.data
            }
            return null
        }
        return []
    }

    const getCurrentMonthSolarProduction = () => {
        if (slide && slide.slideEnergy2 && slide.slideEnergy2) {
            let profiles = slide.slideEnergy2.energyProfile1Y.filter(x => x.resolution === 'p1d')
            let longest = [];
            let id = undefined;
            for (let i = 0; i < profiles.length; i++) {
                if (profiles[i].data.length > longest.length) {
                    longest = JSON.parse(JSON.stringify(profiles[i]));
                    id = profiles[i].id;
                }
            }
            if (longest && longest.data) {
                longest.data.forEach(l => {
                    profiles.forEach(p => {
                        if (p.id !== id) {
                            let f = p.data.find(x => x.timestamp === l.timestamp)
                            if (f)
                                l.value += f.value
                        }
                    })

                })
                return longest.data
            }
            return null
        }
        return []
    }

    let getDate = () => {
        let date = new Date();
        return date.getFullYear() - 1 + '-' + date.getFullYear()
    }

    return (
        <div className={'w-full h-full'}>
            <div className={'pb-2 max-h-1/6 w-full'}>
                <h2 className={'w-full text-2xl text-light-semantic-green-200 dark:text-new-green-100 uppercase ml-1'}
                    style={{letterSpacing: '.5rem'}}>
                    {subtitle}
                </h2>
                {/* 
                <h1 className={'w-full text-4xl text-dark-grayscale-8 dark:text-dark-grayscale-1'}>
                </h1>
                */}

                <div className={'flex justify-between'}>
                    <div className={'w-3/4 text-5xl  font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1'}
                         style={{letterSpacing: '.25rem'}}>
                        {title}
                    </div>
                    <div className={'flex'}>
                        <span
                            className={'w-full text-2xl text-dark-grayscale-6 dark:text-dark-grayscale-2 uppercase mr-3 mt-1'}
                            style={{letterSpacing: '.25rem'}}>
                            {getDate()}
                        </span>
                        <img
                            className={'calendar-icon'}
                            src={CalendarIcon}
                            alt={'icon'}
                            style={{
                                fill: 'white',
                                height: '2.4rem'
                            }}
                        />
                        <img
                            className={'calendar-icon-dark'}
                            src={CalendarDarkIcon}
                            alt={'icon'}
                            style={{
                                fill: 'white',
                                height: '2.4rem'
                            }}
                        />

                    </div>
                </div>

                <div className='netzero__kpi flex flex-row'>
                    <div className='netzero__kpi--card flex'>
                        <div className='netzero__kpi--left'>
                            <div className='mt-2 mr-3' style={{
                                backgroundColor: '#5D9A79',
                            }}></div>
                        </div>
                        <div className='netzero__kpi--right'>
                            <div className='text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0 flex'>{formatNumber(current)}
                                <span className='text-1xl ml-1' style={{ marginTop: 'auto' }}> kWh</span>
                            </div>
                            <div className='text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4'>
                                {<Trans i18nKey={'common:periods.currentYear'} >Current Year</Trans>}
                            </div>
                        </div>
                    </div>

                    <div className='netzero__kpi--card flex ml-10'>
                        <div className='netzero__kpi--left'>
                            <div className='mt-2 mr-3' style={{
                                backgroundColor: '#2e443e',
                            }}></div>
                        </div>
                        <div className='netzero__kpi--right'>
                            <div className='text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0 flex'>
                                {formatNumber(compare)}
                                <span className='text-1xl ml-1' style={{ marginTop: 'auto' }}> kWh</span>
                            </div>
                            <div className='text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4'>
                                {<Trans i18nKey={'common:periods.lastYear'} >Last Year</Trans>}
                            </div>
                        </div>
                    </div>

                    <div className='netzero__kpi--card flex ml-10'>
                        <div className='netzero__kpi--left'>
                            <div className={diff > 0 ? 'mt-2 mr-3 up' : 'mt-2 mr-3 down'}></div>
                        </div>
                        <div className='netzero__kpi--right'>
                            <div className='text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0 flex'>
                                {compare !== 0 ? diff > 0 ? '+' + formatNumber(diff, 1) : formatNumber(diff, 1) : '-'}
                                <span className='text-1xl ml-1' style={{ marginTop: 'auto' }}> %</span>
                            </div>
                            <div className='text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4'>
                                {<Trans i18nKey={'change'} >Change</Trans>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {historicSolarProduction || currentMonthSolarProduction ? (
                <div className={`h-2/3 max-h-2/3 w-full`}>
                    <VerticalBarChartNew
                        primaryData={historicSolarProduction}
                        currentMonth={currentMonthSolarProduction}
                    />
                </div>
            ) : (
                <div
                    className={`h-5/6 max-h-5/6 w-full rounded-2xl flex items-center justify-center`}>
                    <div className={"flex flex-col items-center space-y-4"}>
                        <img src={isLightTheme ? EmptyChartBlack : EmptyChartWhite} className={"opacity-20 h-40 w-40"} alt="Empty gif"/>
                        <h1 className={"text-4xl text-dark-grayscale-6 dark:text-dark-grayscale-3"}>{i18n.t("digitalSignage:waitingData")}</h1>
                    </div>
                </div>
            )}
        </div>
    )
}

export default HistoricProduction;