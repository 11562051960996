import Rect from './SankeyPartials/Rect';
import {Trans} from "react-i18next";
import {useEffect, useState} from 'react';
import {formatNumber} from './helpers/numeralFormatter';
import {calculateCoordinatesConsumers} from './helpers/calculateProviders';
import {calculateCoordinatesSuppliers} from './helpers/calculateSupliers';
import CurvedLine from "./v1/SankeyChart/SankeyPartials/CurvedLine";

function SankeyChart({ isLightTheme, lang, flowwData, slideIndex, is7days }) {
    const [hasData, setHasData] = useState(false)
    const [consumers, setConsumers] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [isSmallHeight, setIsSmallHeight] = useState(false)
    const [suppliersSum, setSuppliersSum] = useState(false)
    const [producedSum, setProducedSum] = useState(0)
    const [consumersSum, setConsumersSum] = useState(0)
    const [soldSum, setSoldSum] = useState(0)
    const [consumersWithCalculations, setConsumersWithCalculations] = useState([])
    const [suppliersWithCalculations, setSuppliersWithCalculations] = useState([])
    const [height, setHeight] = useState(0)
    const [randomNumber, setRandom] = useState(0)

    useEffect(() => {
        setRandom(Math.floor(Math.random() * 1000))
        if (flowwData)
            splitData()
    }, [flowwData])

    useEffect(() => {
        if (suppliersWithCalculations.length && consumersWithCalculations.length) {
            if (isSmallHeight) {
                document.getElementById('sankey-svg-' + randomNumber).setAttribute('viewBox', '0 0 500 100')
                document.getElementById('sankey-svg-' + randomNumber).setAttribute('height', '100%')
                document.getElementsByClassName('App')[0].style.width = '100%'
            } else {
                let heightTemp = 0
                if (consumersWithCalculations.length >= suppliersWithCalculations.length) {
                    heightTemp = consumersWithCalculations.length * 36
                } else {
                    heightTemp = suppliersWithCalculations.length * 36
                }
                heightTemp += 54
                setHeight(heightTemp)
                document.getElementById('sankey-svg-' + randomNumber).setAttribute('viewBox', `0 0 500 ${heightTemp + 20}`)
            }
            consumersWithCalculations.forEach((item, index) => {
                let canvas = document.getElementById(`unit-suppliers-${index}-${randomNumber}`).getComputedTextLength();
                document.getElementById(`unit-suppliers-${index}-${randomNumber}`).setAttribute('x', 470 - canvas)
                let unit = document.getElementById(`unit-suppliers-${index}-${randomNumber}`).getAttribute('x')
                let valueLength = document.getElementById(`suppliers-${index}-${randomNumber}`).getComputedTextLength()
                document.getElementById(`suppliers-${index}-${randomNumber}`).setAttribute('x', unit - valueLength - 3)
                document.getElementById(`name-suppliers-${index}-${randomNumber}`).setAttribute('letter-spacing', 1)
                let nameLength = document.getElementById(`name-suppliers-${index}-${randomNumber}`).getComputedTextLength()
                document.getElementById(`name-suppliers-${index}-${randomNumber}`).setAttribute('x', item.x3 - nameLength + 1)
                let percentagesuppliers = document.getElementById(`percentage-suppliers-${index}-${randomNumber}`).getComputedTextLength()
                document.getElementById(`percentage-suppliers-${index}-${randomNumber}`).setAttribute('x', item.x3 - percentagesuppliers)
                let percentageValueLength = document.getElementById(`percentage-value-suppliers-${index}-${randomNumber}`).getComputedTextLength()
                document.getElementById(`percentage-value-suppliers-${index}-${randomNumber}`).setAttribute('x', item.x3 - percentagesuppliers - percentageValueLength)
            })
            suppliersWithCalculations.forEach((item, index) => {
                let canvas = document.getElementById(`data-${index}-${randomNumber}`).getComputedTextLength();
                document.getElementById(`unit-providers-${index}-${randomNumber}`).setAttribute('x', item.x1 + canvas + 3)
                //let dataY = document.getElementById(`data-${index}-${randomNumber}`).getAttribute('y') - 7
                document.getElementById(`name-providers-${index}-${randomNumber}`).setAttribute('letter-spacing', 1)
                let percentageValueLength = document.getElementById(`percentage-value-providers-${index}-${randomNumber}`).getComputedTextLength()
                document.getElementById(`percentage-providers-${index}-${randomNumber}`).setAttribute('x', item.x1 + percentageValueLength + 1)
            })
        }

    }, [suppliersWithCalculations, consumersWithCalculations])

    let getDate = () => {
        let date = new Date();
        let from = new Date();
        from.setDate(date.getDate() - 7);
        //let monthNames = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
        const monthNamesNo = ["januar ", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"];
        if(is7days)
            return from.getUTCDate() + '. ' + monthNamesNo[from.getMonth()].toUpperCase() + ' ' + from.getFullYear() + ' - '
                + date.getUTCDate() + '. ' + monthNamesNo[date.getMonth()].toUpperCase() + ' ' + date.getFullYear()
        return monthNamesNo[from.getMonth()].toUpperCase() + ' ' + (Number(from.getFullYear()) - 1) + ' - '
            + monthNamesNo[date.getMonth()].toUpperCase() + ' ' + date.getFullYear()
    }


    const percentagesuppliers = (value) => {
        let sum = 0
        suppliers.forEach(item => {
            sum += item.valueCurrentPeriod
        })
        return round((value / sum) * 100, 0)
    }
    const percentageConsumers = (value) => {
        let sum = 0
        consumers.forEach(item => {
            sum += item.valueCurrentPeriod
        })
        return ((value / sum) * 100 < 1) ? round((value / sum) * 100, 2) : round((value / sum) * 100, 0)
    }


    const splitData = () => {
        let producedSumTemp = 0;
        let suppliersSumTemp = 0
        let consumersSumTemp = 0
        let soldSumTemp = 0;

        let suppliersTemp = flowwData.purchased;
        let producedTemp = flowwData.produced;
        let soldEnergyTemp = flowwData.sold;
        let consumersTemp = flowwData.consumed;


        suppliersTemp.forEach(x => {
            suppliersSumTemp += x.valueCurrentPeriod
            x.color = 'ef8d5d'
        })
        
        producedTemp.forEach(x => {
            producedSumTemp += x.valueCurrentPeriod
            x.color = '3fb68e'
        })

        suppliersTemp = [...suppliersTemp, ...producedTemp]

        consumersTemp.forEach(e => {
            consumersSumTemp += e.valueCurrentPeriod
            e.color = 'f06a93'
            e.isConsumer = true
        })

        console.log(consumersTemp)
        let othersSum = 0;
        if (consumersTemp && consumersTemp.length > 3) {
            consumersTemp = consumersTemp.sort((a, b) => a.valueCurrentPeriod < b.valueCurrentPeriod ? 1 : -1)
            let top3Consumers = []
            consumersTemp.forEach((cons, index) => {
                console.log(cons, index)
                if(index < 3)
                    top3Consumers.push(cons)
                else
                    othersSum += cons.valueCurrentPeriod
            })
            consumersTemp = top3Consumers
        }

        soldEnergyTemp.forEach(e => {
            soldSumTemp += e.valueCurrentPeriod;
            e.color = '74abe2'
        })


        if (suppliersSumTemp + producedSumTemp > consumersSumTemp + soldSumTemp) {
            let sum = suppliersSumTemp + producedSumTemp - consumersSumTemp - soldSumTemp
            consumersTemp.push({
                name: lang === 'nb-NO' ? 'ANNET' : 'OTHER',
                color: 'f06a93',
                icon: 'threedots',
                valueCurrentPeriod: sum + othersSum,
                valuePreviousPeriod: 0,
                isConsumer: true
                
            })
            consumersSumTemp += sum
        }

        if (soldEnergyTemp.length){
            consumersTemp = [...consumersTemp, ...soldEnergyTemp]
        }
        if (suppliersTemp.length <= 1 && consumersTemp.length <= 1) {
            setIsSmallHeight(true)
        }

        setSuppliers(suppliersTemp)
        setConsumers(consumersTemp)
        setProducedSum(producedSumTemp)
        setSuppliersSum(suppliersSumTemp)
        setConsumersSum(consumersSumTemp)
        setSoldSum(soldSumTemp)
        setConsumersWithCalculations(calculateCoordinatesConsumers(false, false, soldEnergyTemp, consumersTemp, isSmallHeight, suppliersTemp))
        setSuppliersWithCalculations(calculateCoordinatesSuppliers(false, false, suppliersTemp, isSmallHeight, consumersTemp))
        setHasData(true)
    }

    const round = (value, precision) => {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }


    const setUoM = (tagType, value = null, factor = null) => {
        let result = {
            value: '',
            unit: ''
        };
        let organizationUoM = {
            "id": 1,
            "name": "kWh, °C, m, kg, ...",
            "units": [
                {
                    "id": 1,
                    "tagType": 1,
                    "name": "kWh",
                    "name103": "MWh",
                    "name106": "GWh",
                    "name109": "TWh"
                },
                {
                    "id": 2,
                    "tagType": 3,
                    "name": "m<sup>2</sup>",
                    "name103": null,
                    "name106": null,
                    "name109": null
                },
                {
                    "id": 3,
                    "tagType": 4,
                    "name": "°C",
                    "name103": null,
                    "name106": null,
                    "name109": null
                },
                {
                    "id": 4,
                    "tagType": 6,
                    "name": "kW",
                    "name103": null,
                    "name106": null,
                    "name109": null
                },
                {
                    "id": 5,
                    "tagType": 7,
                    "name": "ppm",
                    "name103": null,
                    "name106": null,
                    "name109": null
                },
                {
                    "id": 6,
                    "tagType": 8,
                    "name": "%RH",
                    "name103": null,
                    "name106": null,
                    "name109": null
                },
                {
                    "id": 7,
                    "tagType": 9,
                    "name": "lux",
                    "name103": null,
                    "name106": null,
                    "name109": null
                },
                {
                    "id": 8,
                    "tagType": 11,
                    "name": "dB",
                    "name103": null,
                    "name106": null,
                    "name109": null
                },
                {
                    "id": 9,
                    "tagType": 12,
                    "name": "ppb",
                    "name103": null,
                    "name106": null,
                    "name109": null
                },
                {
                    "id": 10,
                    "tagType": 13,
                    "name": "Bq/m<sup>3</sup>",
                    "name103": null,
                    "name106": null,
                    "name109": null
                },
                {
                    "id": 11,
                    "tagType": 15,
                    "name": "kPa",
                    "name103": null,
                    "name106": null,
                    "name109": null
                },
                {
                    "id": 12,
                    "tagType": 17,
                    "name": "kg",
                    "name103": "t",
                    "name106": "kt",
                    "name109": "Mt"
                },
                {
                    "id": 13,
                    "tagType": 20,
                    "name": "m<sup>3</sup>",
                    "name103": null,
                    "name106": null,
                    "name109": null
                },
                {
                    "id": 14,
                    "tagType": 18,
                    "name": "g/kWh",
                    "name103": "kg/kWh",
                    "name106": "t/kWh",
                    "name109": "kt/kWh"
                },
                {
                    "id": 15,
                    "tagType": 21,
                    "name": "m<sup>3</sup>",
                    "name103": null,
                    "name106": null,
                    "name109": null
                }
            ]
        }
        let selectedItem = organizationUoM.units.find(x => x.tagType === tagType);

        if (selectedItem !== undefined) {

            if (factor === 1e-9) {
                result = {
                    value: value * 1e9,
                    unit: selectedItem.name109,
                };
            } else if (factor === 1e-6) {
                result = {
                    value: value * 1e-6,
                    unit: selectedItem.name106,
                };

            } else if (factor === 1e-3) {
                result = {
                    value: value * 1e-3,
                    unit: selectedItem.name103,
                };

            } else {
                result = {
                    value: value,
                    unit: selectedItem.name,
                };
            }

            return result;
        }

        return result;
    }

    return (
    
        
        <div className={`buildingChart w-full h-full `} >
            <div className={'w-full h-full'}  >
                <div className={'pb-6 max-h-1/6 w-full '}>
                    <h2 className={'w-full text-2xl text-light-semantic-green-200 dark:text-new-green-100 uppercase ml-1'} style={{ letterSpacing: '.5rem' }}>
                        {
                            is7days ?
                                <Trans i18nKey={'sankeyChart.subtitle'} >Last 7 days</Trans>
                            :
                                <Trans i18nKey={'sankeyChart.solar.last12months'} >Last 12 months</Trans>
                        }
                    </h2>
                    <div className={'flex justify-between'}>
                        <div className={'w-1/2 text-5xl  font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 FamiljenGrotesk'} style={{ letterSpacing: '.25rem' }}>
                            {<Trans i18nKey={'sankeyChart.title'} >Energy flow chart</Trans>}
                        </div>
                        <div className={'flex'}>
                            <span className={'w-full text-2xl text-dark-grayscale-6 dark:text-dark-grayscale-2 uppercase mr-3 mt-1'} style={{ letterSpacing: '.25rem' }}>
                                {getDate()}
                            </span>


                        </div>
                    </div>
                </div>
    
    <div style={{height: 'fit-content'}}>
            {hasData && consumers.length && suppliers.length && <div className="App"
                style={{
                    height: '100%',
                    marginTop: consumers.length > 4 ? '30px' : (consumers.length === 1 && suppliers.length === 2) ? '70px' : '60px'
                }}>
                
                    <svg id={"sankey-svg-" + randomNumber} width="100%" style={{overflow: "visible"}} viewBox="0 0 500 400">
                    {
                        suppliers.length && suppliersWithCalculations.map((item, index) => {
                            return <g key={index}>
                                {(
                                        (index === Math.floor(suppliers.length / 2) && suppliers.length % 2 !== 0)
                                        ||
                                        (index < suppliers.length / 2 && item.width > 30 && suppliers.length < consumers.length && !(index === Math.floor(suppliers.length / 2)))
                                        ||
                                        (index < suppliers.length / 2 && !(item.width > 30 && suppliers.length < consumers.length) && !(index === Math.floor(suppliers.length / 2)))
                                        ||
                                        (index >= suppliers.length / 2 && !(index === Math.floor(suppliers.length / 2) && suppliers.length % 2 !== 0))
                                    )
                                    &&
                                    <g>
                                        {   
                                            item.icon ? 
                                            <image x="0" y={item.y1 - 10} width="20" height="20" fill='#B4B9CC'
                                                    href={`/sankeyIcons/${item.icon}.svg`}/> : ''
                                        }
                                        <text id={`data-${index}-${randomNumber}`} x={item.x1} y={item.y1 - 5 - item.width / 2}
                                              fontWeight="bold" fontSize={suppliers.length <= 3 ? 11 : 9}
                                              fill={isLightTheme ? '#334' : '#EEF'}
                                              dy=".3em">{formatNumber(setUoM(1, item.valueCurrentPeriod).value, 0, true)}</text>
                                        <text id={`unit-providers-${index}-${randomNumber}`} x={item.x1}
                                              y={item.y1 - 5.5 - item.width / 2 + 1}
                                              fontWeight="bold" fontSize="9" fill={isLightTheme ? '#334' : '#EEF'}
                                              dy=".3em">{setUoM(1, item.valueCurrentPeriod).unit}</text>
                                        <text id={`name-providers-${index}-${randomNumber}`} x={item.x1}
                                              y={item.y1 - 15 - item.width / 2}
                                              fontSize={9} fontWeight="bold"
                                              fill="#bec2d1"
                                              dy=".3em">{item.name.toUpperCase()}</text>
                                        <CurvedLine x1={item.x1} x2={item.x2} x3={item.x3} y1={item.y1} y2={item.y2}
                                                    width={item.width} fill={item.color ? item.color : '000000'}
                                                    isLightTheme={isLightTheme}
                                                    side="left"/>
                                        <text id={`percentage-value-providers-${index}-${randomNumber}`} x={item.x1}
                                              y={item.y1 + item.width / 2 + 9} fontSize={12}
                                              fill={`${item.color ? `#${item.color}` : '#000000'}`} fontWeight="bold"
                                              dy=".3em">{percentagesuppliers(item.valueCurrentPeriod)}</text>
                                        <text id={`percentage-providers-${index}-${randomNumber}`} x={item.x1}
                                              y={item.y1 + item.width / 2 + 9}
                                              fontSize={12} fill={`${item.color ? `#${item.color}` : '#000000'}`}
                                              fontWeight="bold" dy=".3em">%
                                        </text>
                                    </g>
                                }
                            </g>

                        })
                    }
                    {
                        consumers.length && consumersWithCalculations.map((item, index) => {
                            return <g key={index + 'a'}>
                                {(
                                        (index === Math.floor(consumers.length / 2) && consumers.length % 2 !== 0) ||
                                        (index < consumers.length / 2 && !(index === Math.floor(consumers.length / 2) && consumers.length % 2 !== 0))
                                        || (index >= consumers.length / 2 && !(index === Math.floor(consumers.length / 2) && consumers.length % 2 !== 0))
                                    )
                                    &&
                                    <g>
                                        <text id={`suppliers-${index}-${randomNumber}`} x={item.x3 - 60}
                                              y={item.y2 - 5 - item.width / 2}
                                              fontWeight="bold" fontSize={consumers.length <= 3 ? 11 : 9}
                                              fill={isLightTheme ? '#334' : '#EEF'}
                                              dy=".3em">{formatNumber(setUoM(1, item.valueCurrentPeriod).value, 0)}</text>
                                        <text id={`unit-suppliers-${index}-${randomNumber}`} x={item.x3 - 60}
                                              y={item.y2 - 5.5 - item.width / 2 + 1} fontWeight="bold" fontSize={9}
                                              fill={isLightTheme ? '#334' : '#EEF'}
                                              dy=".3em">{setUoM(1, item.valueCurrentPeriod).unit}</text>
                                        <CurvedLine x1={item.x1} x2={item.x2} x3={item.x3} y1={item.y1} y2={item.y2}
                                                    width={item.width} fill={item.color ? item.color : '000000'}
                                                    isLightTheme={isLightTheme}
                                                    side="right"/>
                                        <text id={`name-suppliers-${index}-${randomNumber}`} x={item.x1}
                                              y={item.y2 - 15 - item.width / 2}
                                              fontSize={9} fontWeight="bold"
                                              fill="#bec2d1"
                                              dy=".3em">{item.name.toUpperCase()}</text>
                                        <text id={`percentage-value-suppliers-${index}-${randomNumber}`} x={item.x3}
                                              y={item.y2 + item.width / 2 + 9} fontSize={12}
                                              fill={`${item.color ? `#${item.color}` : '#000000'}`} fontWeight="bold"
                                              dy=".3em">{percentageConsumers(item.valueCurrentPeriod)}</text>
                                        <text id={`percentage-suppliers-${index}-${randomNumber}`} x={item.x3}
                                              y={item.y2 + item.width / 2 + 9}
                                              fontSize={12} fill={`${item.color ? `#${item.color}` : '#000000'}`}
                                              fontWeight="bold" dy=".3em">%
                                        </text>
                                        <g> {item.icon ? 
                                            <image x="475" y={item.y2 - 10} width="20" height="20" fill='#B4B9CC'
                                                    href={`/sankeyIcons/${item.icon}.svg`}/>
                                                    :
                                                    ''
                                                }
                                        </g>
                                    </g>
                                }
                            </g>
                        })
                    }
                    <Rect startX={180} startY={isSmallHeight ? 25 : height / 2 - 18}  suppliersSum={suppliersSum}
                        producedSum={producedSum} consumersSum={consumersSum} soldSum={soldSum} isLightTheme={isLightTheme}
                    />
                </svg>
            </div>}
        </div>
        </div>
        </div>
    );
}

export default SankeyChart;
