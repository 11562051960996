const ImageSlide = ({ slide }) => {
    return (
        <div className={'w-screen h-screen'}>
            <div className={'absolute z-10 w-screen h-screen bg-em-green-transparent-9'}>
                {(
                    slide &&
                    slide.slideImage &&
                    slide.slideImage.image &&
                    slide.slideImage.image.url
                )
                    ? <img
                        className={'w-full h-full'}
                        alt="Pic"
                        src={slide.slideImage.image.url}
                    />
                    : false
                }
            </div>
        </div>
    )
}

export default ImageSlide;