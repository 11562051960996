const initialState = {
    id: '',
    name: '',
    slides: [],
    slideshow: null,
    buildingLocations: [],
    currentBuildingLocation: {},
    lightTheme: false,
    organization: {},
};

const slideshowReducer = (state = initialState, action) => {
    if (action.type === 'SET_SLIDE_DATA') {
        return Object.assign({}, state, {
            id: action.id,
            name: action.name,
            slides: action.slides,
            slideshow: action.slideshow,
            lightTheme: action.lightTheme,
            organization: action.organization
        });
    }
    else if (action.type === 'SET_ALL_LOCATIONS') {
        return Object.assign({}, state, {
            buildingLocations: action.locations
        });
    }
    else if (action.type === 'SET_CURRENT_LOCATION') {
        return Object.assign({}, state, {
            currentBuildingLocation: {
                lat: action.lat,
                lng: action.lng
            }
        });
    }
    else if (action.type === 'SET_LIGHT_THEME') {
        return Object.assign({}, state, {
            lightTheme: action.lightTheme
        });
    }
    return state;
};

export default slideshowReducer;
