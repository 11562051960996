import { formatNumber } from '../../helpers/numeral';

const SmallWeatherCard = ({ icon, iconAlt, value, unit, windDirection }) => {
    return (
        <div
            className={'rounded-xl flex justify-center items-center h-full w-1/2 bg-em-gray-transparent-65 p-2'}
            style={{
                minHeight: 100,
                width: '12.12%'
            }}
        >
            <img
                src={icon}
                alt={iconAlt}
                style={{
                    transform: `rotate(${windDirection ? windDirection : 0}deg)`
                }}
            />

            <h1
                className={'text-center text-em-white-500 text-4xl font-bold customml-1'}
            >
                {formatNumber(value, 1)} <span className={'text-2xl font-normal'}>{unit}</span>
            </h1>
        </div >
    )
}

export default SmallWeatherCard;