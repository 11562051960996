import { useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Trans, useTranslation } from 'react-i18next';
import EnergyIcon from '../../../assets/icons/tag_ENERGYMETER.svg';
import AreaIcon from '../../../assets/icons/tag_AREA.svg';
import BinaryIcon from '../../../assets/icons/tag_BINARY.svg';
import Co2Icon from '../../../assets/icons/co2_blue.svg';
import COPIcon from '../../../assets/icons/tag_COP.svg';
import CounterIcon from '../../../assets/icons/tag_COUNTER.svg';
import DiscreteIcon from '../../../assets/icons/tag_DISCRETE.svg';
import CostIcon from '../../../assets/icons/tag_COST.svg';
import GasIcon from '../../../assets/icons/tag_GAS.svg';
import PowerIcon from '../../../assets/icons/tag_POWER.svg';
import PressureIcon from '../../../assets/icons/tag_PRESSURE.svg';
import RadonIcon from '../../../assets/icons/tag_RADON.svg';
import SoundIcon from '../../../assets/icons/tag_SOUND.svg';
import VOCIcon from '../../../assets/icons/tag_VOC.svg';
import WaterIcon from '../../../assets/icons/tag_WATER.svg';
import WeightIcon from '../../../assets/icons/tag_WEIGHT.svg';


const Card = (data) => {
    const [tagResponse, setTagResponse] = useState(undefined)
    const [tagType, setTagType] = useState(undefined)
    const [uOM, setUOM] = useState(undefined)
    const [isOpened, setIsOpened] = useState(false);
    const [isChartLoaded, setIsChartLoaded] = useState(false);
    const [options, setOptions] = useState(undefined);
    const [currentLevel, setCurrentLevel] = useState(undefined);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [tdata, setTdata] = useState('data');

    const icons = {
        'ENERGYMETER': EnergyIcon,
        'AREA': AreaIcon,
        'BINARY': BinaryIcon,
        'CO2FACTOR': Co2Icon,
        'COP': COPIcon,
        'COUNTERMETER': CounterIcon,
        'COUNTERSENSOR': CounterIcon,
        'DISCRETE': DiscreteIcon,
        'FIXEDCOST': CostIcon,
        'COSTFACTOR': CostIcon,
        'GASMETER': GasIcon,
        'POWER': PowerIcon,
        'PRESSURE': PressureIcon,
        'RADON': RadonIcon,
        'SOUND': SoundIcon,
        'VOC': VOCIcon,
        'WATERMETER': WaterIcon,
        'WEIGHT': WeightIcon,
    }

    const { t, i18n } = useTranslation()

    function setChartOptions(data, categoriesData, tagType){
        setOptions({
            chart: {
                type: 'areaspline',
                height: 300,
            },
            yAxis: {
                title: {
                    enabled: false
                }
            },
            xAxis: {
                categories: categoriesData
            },
            title: {
                text: ''
            },
            plotOptions: {
                series: {
                    fillColor: {
                        linearGradient: [0, 0, 0, 300],
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    return 'Date: <b>' + this.point.options.date + '</b><br>Value: <b>' + Number.parseFloat(this.y).toFixed(1) + ' ' + uOM + '</b>';
                }
            },
            series: [{ data: data, name: t('admin:tags.enum.tagTypes.' + tagType) + ' ' + t('common:data').toLowerCase() }]
        })

        setCurrentLevel(tagResponse.data[tagResponse.data.length - 1].value)
        setIsChartLoaded(true)
    }

    useEffect(() => {
        i18n.changeLanguage("en-US")
        setTdata(t('common:data').toLowerCase())
        if (data !== undefined && data) {
            setTagResponse(data.data)
            setTagType(data.data.type)
            setUOM(data.data.unit)
        }
    }, [data]);
    
    useEffect(() => {
        if (tagResponse) {
            let seriesData = [];
            let categoriesData = [];
            tagResponse.data.forEach(e => {
                let date = new Date(e.timestamp);
                let str = date.getUTCDate() + '. ' + months[date.getUTCMonth()]
                let hours = date.getUTCHours(); if (hours < 10) hours = '0' + hours;
                let minutes = date.getUTCMinutes(); if (minutes < 10) minutes = '0' + minutes;
                let strFull = str + ' ' + date.getUTCFullYear() + '. ' + hours + ':' + minutes;
                seriesData.push({date: strFull, y: e.value})
                categoriesData.push(str)
            });
            setChartOptions(seriesData, categoriesData, data.data.type)
        }
    }, [tagResponse]);

    
    return (
        <div className="tag__card" onClick={
            function(){
                setIsOpened(!isOpened);
            }
        }>
            <div className='tag__card--header'>
                <div className='tag__card--header-top'>
                    <div>
                        <img alt="icon" src={icons[tagType]}></img>

                        <h3>{<Trans i18nKey={'admin:tags.enum.tagTypes.' + data.data.type } ></Trans>}</h3>
                    </div>
                    <h2>{Number.parseFloat(currentLevel).toFixed(1)} <b dangerouslySetInnerHTML={{ __html: uOM }}></b></h2>
                </div>
                <div className='tag__card--header-bottom'>
                    <span>{<Trans i18nKey={'admin:tags.enum.tagTypes.' + data.data.type} ></Trans>} {tdata}</span>
                    <div className='tag__card--expander'>
                        <span>&#128315;</span>
                    </div>
                </div>
            </div>
            {isChartLoaded && isOpened && <div className='tag__charts--chart'>
                {
                    options.series &&
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                }
            </div>}
        </div>
    )
}

export default Card;