//Assets
import SlideBackground from '../assets/images/message_bg.svg';
import Logo from '../assets/images/em_logo_all_purple_twolines.svg';
import Slogan from '../assets/images/slogan.svg';

const MessageSlide = ({ slide, slideshow }) => {
    //Functions
    const svgToBase64 = (svg) => {
        if (svg)
            return ("data:image/svg+xml;base64," + window.btoa(svg));

        return null
    }

    return (
        <div className={'w-screen h-screen'}>
            <img
                className={'absolute z-0 w-screen h-screen object-cover'}
                src={SlideBackground}
                alt={'Background'}
            />
            <div className={'absolute z-10 w-screen h-screen bg-em-green-transparent-9 p-16'}>
                <h1 className={'text-white mb-16 text-6xl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 FamiljenGrotesk"'}>
                {slide && slide.slideMessage && slide.slideMessage.icon && <i className={slide.slideMessage.icon + ' mr-4 text-white text-6xl'} /> }
                    {(
                        slide &&
                        slide.slideMessage &&
                        slide.slideMessage.title
                    )
                        ? slide.slideMessage.title
                        : false}
                </h1>
                {slide && slide.slideMessage && slide.slideMessage.message &&
                    <div className={'text-white text-5xl'} dangerouslySetInnerHTML={{ __html: slide.slideMessage.message}} /> 
                }
                <div className={'absolute bottom-8 flex justify-between items-end h-24 p-4  space-x-4  bg-em-white-500 p-4 px-6 h-8vh'}
                    style={{
                        borderRadius: 16
                    }}
                >
                    <div class="slogan__animation" >
                        <div>
                            <img
                                class="slogan__animation__logo"
                                src={Logo}
                                alt={"EmLogoWhite"}
                                style={{ height: "4.5vh" }}
                            />
                            <img
                                className="slogan__animation__slogan"
                                src={Slogan}
                                alt={"SloganWhite"}
                                style={{ height: "4.5vh" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`absolute z-10 bottom-0 left-0 w-1 /3 h-2 bg-em-purple-500`} />
            <div className={`absolute z-10 bottom-0 right-0 w-2/3 h-2 bg-em-white-500`} />
        </div>
    )
}

export default MessageSlide;