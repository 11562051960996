import { useEffect, useState } from 'react';

const MessageSlide = ({ slide }) => {
    
    useEffect(() => {
        setSlideItteration(slideItteration + 1);
    }, [slide])

    const [slideItteration, setSlideItteration] = useState(0);

    return (
        <div className={'w-screen h-screen'}>
            <div className={'absolute z-10 w-screen h-screen bg-em-green-transparent-9 pb-2'}>
                {(
                    slide &&
                    slide.slideUrl &&
                    slide.slideUrl.url
                )
                    ? <iframe
                        key={`url_slide_iframe_${slideItteration}`}
                        title="iframe"
                        className={'w-full h-full'}
                        src={slide.slideUrl.url}
                    />
                    : false
                }
            </div>
            <div className={`absolute z-10 bottom-0 left-0 w-1/3 h-2 bg-em-purple-500`} />
            <div className={`absolute z-10 bottom-0 right-0 w-2/3 h-2 bg-em-white-500`} />
        </div>
    )
}

export default MessageSlide;