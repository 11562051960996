export const setSliderData = (id, name, slides, slideshow, lightTheme, organization) => {
    return {
        type: 'SET_SLIDE_DATA',
        id: id,
        name: name,
        slides: slides,
        slideshow: slideshow,
        lightTheme: lightTheme,
        organization: organization
    };
};

export const setAllLocations = (locations) => {
    return {
        type: 'SET_ALL_LOCATIONS',
        locations: locations
    }
}

export const setCurrentLocation = (lat, lng) => {
    return {
        type: 'SET_CURRENT_LOCATION',
        lat: lat,
        lng: lng
    }
}

export const setLightTheme = (lightTheme) => {
    return {
        type: 'SET_LIGHT_THEME',
        lightTheme: lightTheme
    }
}