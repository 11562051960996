import Logo from '../../assets/images/em_logo_all_white.svg';

//Translations
import { Trans } from 'react-i18next';

const Error500 = () => {

    const logoCss = {
        display: 'flex',
        padding: '60px 80px',
    }
    const textCss = {
        display: 'flex',
        maxHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 300px)',
        textAlign: 'center'
    }
    
    return (
        <div className={'dark'}>
            <div className={'w-screen h-screen'}>
                <div className='w-full h-full bg-light-semantic-green-50 dark:bg-dark-grayscale-5'>
                    <div className='absolute z-10 top-0 left-0 right-0 h-2 bg-light-qualitative-magenta'></div>
                    <div style={logoCss} className='bg-dark-grayscale-1 dark:bg-dark-grayscale-6'>
                        <img src={Logo} alt="logo" />
                    </div>
                    <div style={textCss}>
                        <h1 className='text-3xl text-dark-grayscale-8 dark:text-dark-grayscale-1' style={{maxWidth: '60%'}}>
                            <Trans i18nKey={'error.text500'} >Something is stuck... Please wait or try to restart the slideshow.</Trans>
                        </h1>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Error500;