import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/nb';

export const BAR_CHART_OPTIONS = {
    chart: {
        type: 'bar'
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    xAxis: {
        visible: false,
        categories: ['', '', '', 'Marnar ungdomsskole', ''],
        title: {
            text: null
        }
    },
    yAxis: {
        visible: false,
        min: 0,
        title: {
            text: 'Usage (kWh)',
            align: 'middle'
        },
        labels: {
            overflow: 'justify',
        }
    },
    tooltip: {
        enabled: false
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true,
                inside: true,
                style: {
                    fontWeight: 'bold',
                    border: 'none',
                    color: '#C8CBCF',
                    textOutline: 'none',
                }
            }
        },
        series: {
            dataLabels: {
                enabled: true,
                inside: true,
                style: {
                    fontWeight: 'normal'
                }
            }, 
            marker: {
                enabled: false,
            }
        }
    },
    legend: {
        enabled: false
    }
}

export const COLUMN_COMPARISON_CHART_OPTIONS = {
    chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    plotOptions: {
        series: {
            grouping: false,
            borderWidth: 0
        }
    },
    legend: {
        enabled: false
    },
    tooltip: {
        enabled: false,
        shared: true,
        headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} medals</b><br/>'
    },
    xAxis: {
        labels: {
            enabled: true
        },
        categories: [
            moment('1 Jan 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Feb 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Mar 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Apr 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 May 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Jun 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Jul 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Aug 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Sep 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Oct 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Nov 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Dec 2021').locale('nb').format('MMMM').slice(0, 3)
        ],
        crosshair: false
    },
    yAxis: [{
        title: {
            text: ''
        },
        endOnTick: false,
        maxPadding: 0,
        showFirstLabel: false
    }],
    series: [{
        color: 'rgb(158, 159, 163)',
        pointPlacement: -0.2,
        linkedTo: 'main',
        // data: dataPrev[2016].slice(),
        name: 'previousYear'
    }, {
        name: 'currentYear',
        id: 'main',
        // dataSorting: {
        //     enabled: true,
        //     matchByName: true
        // },
        dataLabels: [{
            enabled: true,
            inside: true,
            // style: {
            //     fontSize: '16px'
            // }
        }],
        // data: getData(data[2016]).slice()
    }],
    exporting: {
        allowHTML: true
    }
}

export const LINE_CHART_OPTIONS = {
    title: {
        text: ''
    },

    subtitle: {
        text: ''
    },

    xAxis: {
        labels: {
            enabled: true
        },
        categories: [
            moment('1 Jan 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Feb 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Mar 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Apr 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 May 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Jun 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Jul 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Aug 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Sep 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Oct 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Nov 2021').locale('nb').format('MMMM').slice(0, 3),
            moment('1 Dec 2021').locale('nb').format('MMMM').slice(0, 3)
        ],
    },

    yAxis: {
        endOnTick: false,
        maxPadding: 0,
        title: {
            text: ''
        }
    },

    legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        enabled: false
    },

    plotOptions: {
        series: {
            pointInterval: 1
        }
    },

    tooltip: {
        enabled: false
    },

    series: [{
        name: 'Installation',
        data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
    }, {
        name: 'Manufacturing',
        data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]
    }, {
        name: 'Sales & Distribution',
        data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]
    }, {
        name: 'Project Development',
        data: [null, null, 7988, 12169, 15112, 22452, 34400, 34227]
    }, {
        name: 'Other',
        data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
    }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    enabled: false,
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            }
        }]
    }
}