import SolarInstallationImg from '../../assets/images/solarInstallation.jpg';

const SolarInstallations = ({ solarInstalation }) => {
    return (
        solarInstalation && solarInstalation.image && solarInstalation.image.url ?
            <div 
                className={'w-full h-full solarInstalation'} 
                style={{ backgroundImage: `url(${solarInstalation.image.url})` }}>
            </div>
        :
            <div 
                className={'w-full h-full solarInstalation'} 
                style={{ backgroundImage: `url(${SolarInstallationImg})` }}>
            </div>
    )
}

export default SolarInstallations;