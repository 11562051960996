
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { LINE_CHART_OPTIONS } from '../charts/constants/chartOptions';
import { formatNumber } from '../../helpers/numeral'
import { Trans } from 'react-i18next';
import CalendarIcon from '../../assets/icons/calendar.svg';
import CalendarDarkIcon from '../../assets/icons/calendar-dark.svg';
import moment from 'moment';
import ChartNoData from '../errors/ChartNoData';


const EnergyProduced7D = ({ slide, isLightTheme }) => {
    const [current, setCurrent] = useState(undefined);
    const [sum, setSum] = useState(0);
    const [categories, setCategories] = useState([]);
    const [producedPower, setProducedPower] = useState(0);

    useEffect(() => {
        console.log('EnergyProduced7D')
        calculateProducedEnergy()
    }, []);

    let getDate = () => {
        let date = new Date();
        let fromDate = new Date(); fromDate.setDate(fromDate.getDate() - 7);
        //let monthNames = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
        const monthNamesNo = ["januar ", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"];
        return fromDate.getUTCDate() + '. ' + monthNamesNo[fromDate.getMonth()].toUpperCase() + ' ' + fromDate.getFullYear()
                + ' - ' + date.getUTCDate() + '. ' + monthNamesNo[date.getMonth()].toUpperCase() + ' ' + date.getFullYear()
    }   

    let getCategories = () => {
        return categories
    }

    const calculateProducedEnergy = () => {
        let producedTags = slide.slideEnergy2.tags.filter(x => x.meterConfiguration.meterType === 'INTERNALLY_PRODUCED' && x.tagType === 'ENERGYMETER')
        console.log(producedTags, 'producedTags')
        let producedData = [];
        if (producedTags && producedTags.length){
            producedTags.forEach(tag => {
                let found = slide.slideEnergy2.energyProfile7D.find(x => x.id === tag.id)
                if(found)
                    producedData.push(found)
            });
        }

        let values = []
        let sumTemp = 0
        let categoriesSet = false
        let categoriesTemp = [] 
        if (producedData && producedData.length){
            producedData.forEach(e => {
                e.data.forEach((d, index) => {
                    sumTemp += d.value
                    if(!categoriesSet){
                        values.push(d.value)
                        categoriesTemp.push(moment(d.timestamp).format('DD MMM'))
                    } else if (values && values[index] !== undefined) {
                        values[index] += d.value
                    }
                })
                categoriesSet = true
            })
        }

        setSum(sumTemp)
        setCategories(categoriesTemp)

        console.log(values, 'producedLast7D values')
        setProducedPower(values)
    }

    return (
        <div className={`w-full h-full`} >
            <div className={'pb-12 max-h-1/6 w-full '}>
                <h2 className={'w-full text-2xl text-production uppercase ml-1'} style={{ letterSpacing: '.5rem' }}>
                    {<Trans i18nKey={'last7days'} >Last 7 days</Trans>}
                </h2>
                <div className={'flex justify-between'}>
                    <div className={'w-1/2 text-5xl  font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 FamiljenGrotesk'} style={{ letterSpacing: '.25rem' }}>
                        {<Trans i18nKey={'energyProduced'} >Energy produced</Trans>}
                    </div>
                    <div className={'flex'}>
                        <span className={'w-full text-2xl text-dark-grayscale-6 dark:text-dark-grayscale-2 uppercase mr-3 mt-1'} style={{ letterSpacing: '.25rem' }}>
                            {getDate()}
                        </span>
                        <img
                            className={'calendar-icon'}
                            src={CalendarIcon}
                            alt={'icon'}
                            style={{
                                fill: 'white',
                                height: '2.4rem'
                            }}
                        />
                        <img
                            className={'calendar-icon-dark'}
                            src={CalendarDarkIcon}
                            alt={'icon'}
                            style={{
                                fill: 'white',
                                height: '2.4rem'
                            }}
                        />
                    </div>
                </div>

                <div className='netzero__kpi flex flex-row'>
                    <div className='netzero__kpi--card flex'>
                        <div className='netzero__kpi--left'>
                            <div className='mt-2 mr-3 bg-production'></div>
                        </div>
                        <div className='netzero__kpi--right'>
                            <div className='text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0 flex'>
                                {formatNumber(sum)}
                                <span className='text-1xl ml-1' style={{ marginTop: 'auto' }}> kWh</span>
                            </div>
                            <div className='text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4'>
                                {<Trans i18nKey={'last7days'} >Last 7 days</Trans>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {producedPower && producedPower.length ?
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                ...LINE_CHART_OPTIONS,
                                chart: {
                                    ...LINE_CHART_OPTIONS.chart,
                                    type: 'areaspline',
                                    backgroundColor: 'transparent'
                                },
                                title: {
                                    text: '',
                                },
                                credits: {
                                    enabled: false
                                },
                                colors: ['#5d9a79', '#2e443e'],
                                yAxis: {
                                    labels: {
                                        enabled: true,
                                        formatter: function () {
                                            return this.axis.defaultLabelFormatter.call(this) + ' kWh';
                                        },
                                        style: {
                                            color: '#C8CBCF'
                                        },
                                        x: -40,
                                        y: 15,
                                    },
                                    opposite: true,
                                    title: {
                                        text: null
                                    },
                                    gridLineColor: '#C8CBCF',
                                    gridLineDashStyle: 'Dash',
                                    min: 0
                                },
                                xAxis: {
                                    categories: getCategories(),
                                    labels: {
                                        step: 20
                                    }
                                },
                                
                                series: [
                                    {
                                        name: 'Current',
                                        data: producedPower,
                                        lineWidth: 5
                                    },/*
                                    {
                                        name: 'Current year',
                                        data: [10, 20, 30, 54, 15, 20, 30, 45, 10, 5, 20, 30],
                                        zoneAxis: 'x'
                                    }
                                    */
                                ],

                                plotOptions: { 
                                    ...LINE_CHART_OPTIONS.plotOptions,
                                    series: {
                                        dataLabels: {
                                            enabled: false
                                        },
                                        marker: {
                                            enabled: false,
                                        },
                                        fillColor: {
                                            linearGradient: [0, 0, 0, 600],
                                            stops: [
                                                [0, '#ffffff'],
                                                [0.15, Highcharts.color('#ffffff').setOpacity(0.95).get('rgba')],
                                                [0.35, Highcharts.color('#ffffff').setOpacity(0.45).get('rgba')],
                                                [0.85, Highcharts.color('#3fb68e').setOpacity(0.05).get('rgba')],
                                                [1, Highcharts.color('#3fb68e').setOpacity(0).get('rgba')]
                                            ]
                                        },
                                        color: {
                                            linearGradient: [0, 0, 0, 600],
                                            stops: [
                                                [0, isLightTheme ? '#3fb68e' : '#ffffff'],
                                                [0.5, Highcharts.color(isLightTheme ? '#3fb68e' : '#ffffff').setOpacity(0.5).get('rgba')],
                                                [0.8, Highcharts.color(isLightTheme ? '#aff6de' : '#3fb68e').setOpacity(1).get('rgba')],
                                                [1, Highcharts.color('#3fb68e').setOpacity(0.05).get('rgba')]
                                            ]
                                        }
                                    }
                                },
                            }}
                            containerProps={{
                                style: {
                                    width: '100%',
                                    height: '65%',
                                    borderRadius: 4,
                                    backgroundColor: 'transparent'
                                }
                            }}
                        />
            : 
                <ChartNoData></ChartNoData>
            }
             
        </div >
    )
}

export default EnergyProduced7D;