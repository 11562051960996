//Modules
import { combineReducers } from 'redux';

//Reducers
import slideshowReducer from './slideshow';

const rootReducer = combineReducers({
    slideshow: slideshowReducer,
});

export default rootReducer;
