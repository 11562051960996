//Get slideshow data from API
export const getSlideshow = (key, apiKey) => {
    return new Promise((resolve) => {
        // fetch(process.env.REACT_APP_SLIDESHOW_API_LINK + key, {
        fetch(`https://em-public-api.azurewebsites.net/v1/DigitalSignage/slideshow/${key}`, {
            method: 'GET',
            headers: {
                'x-api-key': apiKey
            }
        })
            .then(res => {
                const STATUS_CODE = res.status;
                res.json().then(json => {
                    if (STATUS_CODE === 200) {
                        resolve({
                            status: STATUS_CODE,
                            data: json
                        })
                    }
                    else {
                        resolve({
                            status: STATUS_CODE,
                            message: 'Something went wrong while fetching slideshow data from the API'
                        })
                    }
                })
            })
    })
}

//Get API key from porivisoning key
export const getApiKey = (provisioningKey) => {
    return new Promise((resolve) => {
        fetch(process.env.REACT_APP_API_LINK + process.env.REACT_APP_API_VERSION + '/APIKey/provisioning/' + provisioningKey, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
            .then(res => {
                const STATUS_CODE = res.status;
                res.json().then(json => {
                    if (STATUS_CODE === 200) {
                        resolve({
                            status: STATUS_CODE,
                            data: json
                        })
                    }
                    else {
                        resolve({
                            status: STATUS_CODE,
                            message: 'Something went wrong while fetching API key with provisioning code'
                        })
                    }
                })
            })
    })
}