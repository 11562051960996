import { useEffect, useState } from 'react';
import TemperatureIcon from '../../../assets/icons/temperature_blue.svg';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Trans, useTranslation } from 'react-i18next';


const TemperatureCard = (data) => {
    const [tagResponse, setTagResponse] = useState(undefined)
    const [isOpened, setIsOpened] = useState(false);
    const [isChartLoaded, setIsChartLoaded] = useState(false);
    const [options, setOptions] = useState(undefined);
    const [currentLevel, setCurrentLevel] = useState(undefined);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const { t } = useTranslation()

    function setChartOptions(data, categoriesData){
        setOptions({
            chart: {
                type: 'areaspline',
                height: 300,
            },
            yAxis: {
                title: {
                    enabled: false
                }
            },
            xAxis: {
                categories: categoriesData
            },
            title: {
                text: ''
            },
            plotOptions: {
                series: {
                    fillColor: {
                        linearGradient: [0, 0, 0, 300],
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    return 'Date: <b>' + this.point.options.date + '</b><br>Value: <b>' + Number.parseFloat(this.y).toFixed(1) + ' °C</b>';
                }
            },
            series: [{ data: data, name: t('tag.TEMPERATURE.series') }]
        })

        setCurrentLevel(tagResponse.data[tagResponse.data.length - 1].value)
        setIsChartLoaded(true)
    }

    useEffect(() => {
        if (data !== undefined && data) {
            setTagResponse(data.data)
        }
    }, [data]);

    useEffect(() => {
        if (tagResponse) {
            let seriesData = [];
            let categoriesData = [];
            tagResponse.data.forEach(e => {
                //seriesData.push({ x: new Date(e.timestamp).valueOf(), y: e.value})
                let date = new Date(e.timestamp);
                let str = date.getUTCDate() + '. ' + months[date.getUTCMonth()]
                let hours = date.getUTCHours(); if (hours < 10) hours = '0' + hours;
                let minutes = date.getUTCMinutes(); if (minutes < 10) minutes = '0' + minutes;
                let strFull = str + ' ' + date.getUTCFullYear() + '. ' + hours + ':' + minutes;
                seriesData.push({date: strFull, y: e.value})
                categoriesData.push(str)
            });
            setChartOptions(seriesData, categoriesData)
        }
    }, [tagResponse]);



    return (
        <div className="tag__card" onClick={
            function(){
                setIsOpened(!isOpened);
            }
        }>
            <div className='tag__card--header'>
                <div className='tag__card--header-top'>
                    <div>
                        <img alt="temperatureicon" src={TemperatureIcon}></img>
                        <h3><Trans i18nKey={'tag.TEMPERATURE.title'} >Temperature</Trans></h3>
                    </div>
                    <h2>{Number.parseFloat(currentLevel).toFixed(1)} <b>°C</b></h2>
                </div>
                <div className='tag__card--header-bottom'>
                    <span><Trans i18nKey={'tag.TEMPERATURE.subtitle'} >Between 18-25°C is advised for comfort</Trans></span>
                    <div className='tag__card--expander'>
                        <span>&#128315;</span>
                    </div>
                </div>
            </div>
            {isChartLoaded && isOpened && <div className='tag__charts--chart'>
                {
                    options.series &&
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                }

            </div>}
        </div>
    )
}

export default TemperatureCard;