//CSS
import "./App.css";

//Components
import Slider from "./components/carousel/Slider";
import Tag from "./components/tag/Tag";
import GroupSlide from "./screens/GroupSlide";

//Modules
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Microsfot application insights
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import { ErrorBoundary } from "react-error-boundary";
import NoDataIcon from "./assets/icons/nodata.svg";
import { sleep } from "./helpers/promises";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/group">
          <div className="dark">
            <GroupSlide />
          </div>
        </Route>
        <Route exact path="/tag/:id">
          <Tag />
        </Route>
        <Route path="/:id">
          <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
            <Slider />
          </ErrorBoundary>
        </Route>
      </Switch>
    </Router>
  );
}

function Fallback({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return (
    <div className="error__message" role="alert">
      <div className="error__message--icon">
        <img src={NoDataIcon} alt="nodata" style={{ width: "8vw" }}></img>
      </div>
      <p>Something went wrong. Try restarting your slideshow.</p>
      <div className="error__trace">
        <pre style={{ color: "red" }}>{error.message}</pre>
        <pre style={{ color: "gray" }}>{error.stack}</pre>
      </div>
    </div>
  );
}

const logError = async (error, info) => {
  await sleep(300000);
  window.location.reload();
};

export default withAITracking(reactPlugin, App);
