
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { LINE_CHART_OPTIONS } from '../charts/constants/chartOptions';
import { formatNumber } from '../../helpers/numeral'
import { Trans } from 'react-i18next';
import CalendarIcon from '../../assets/icons/calendar.svg';
import CalendarDarkIcon from '../../assets/icons/calendar-dark.svg';
import moment from 'moment';


const EnergyConsumption = ({ slide }) => {
    const [current, setCurrent] = useState(undefined);
    const [today, setToday] = useState(0);
    const [categories, setCategories] = useState([]);
    const [produceddPower, setPurchasedPower] = useState(0);

    useEffect(() => {
        calculatePurchasedPower()

    }, []);

    let getDate = () => {
        let date = new Date();
        //let monthNames = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
        const monthNamesNo = ["januar ", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"];
        return moment().format('HH:mm') + ' ' + date.getUTCDate() + '. ' + monthNamesNo[date.getMonth()].toUpperCase() + ' ' + date.getFullYear()
    }   

    let getCategories = () => {
        return categories
    }

    const calculatePurchasedPower = () => {
        let purchasedTags = slide.slideEnergy2.tags.filter(x => x.meterConfiguration.meterType === 'EXTERNALLY_IN' && x.tagType === 'POWER')
        console.log(purchasedTags, 'purchasedTags')
        let producedData = [];
        if (purchasedTags && purchasedTags.length){
            purchasedTags.forEach(tag => {
                let found = slide.slideEnergy2.liveFlow.find(x => x.id === tag.id)
                if(found)
                    producedData.push(found)
            });
        }

        let values = []
        let todayTemp = 0
        let categoriesSet = false
        let categoriesTemp = [] 
        if (producedData && producedData.length){
            producedData.forEach(e => {
                e.data.forEach((d, index) => {
                    todayTemp += d.value
                    if(!categoriesSet){
                        values.push(d.value)
                        categoriesTemp.push(moment(d.timestamp).format('HH:mm'))
                    } else if (values && values[index] !== undefined) {
                        values[index] += d.value
                    }
                })
                categoriesSet = true
            })
        }

        setToday(todayTemp)
        setCategories(categoriesTemp)

        console.log(producedData, 'producedData')
        setPurchasedPower(values)
        setCurrent(values[values.length-1])
    }

    return (
        <div className={`w-full h-full`} >
            <div className={'pb-12 max-h-1/6 w-full '}>
                <h2 className={'w-full text-2xl text-light-semantic-green-200 dark:text-new-green-100 uppercase ml-1'} style={{ letterSpacing: '.5rem' }}>
                    {<Trans i18nKey={'common:periods.today'} >Today</Trans>}
                </h2>
                <div className={'flex justify-between'}>
                    <div className={'w-1/2 text-5xl  font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 FamiljenGrotesk'} style={{ letterSpacing: '.25rem' }}>
                        {<Trans i18nKey={'energyPurchased'} >Energy purchased</Trans>}
                    </div>
                    <div className={'flex'}>
                        <span className={'w-full text-2xl text-dark-grayscale-6 dark:text-dark-grayscale-2 uppercase mr-3 mt-1'} style={{ letterSpacing: '.25rem' }}>
                            {getDate()}
                        </span>
                        <img
                            className={'calendar-icon'}
                            src={CalendarIcon}
                            alt={'icon'}
                            style={{
                                fill: 'white',
                                height: '2.4rem'
                            }}
                        />
                        <img
                            className={'calendar-icon-dark'}
                            src={CalendarDarkIcon}
                            alt={'icon'}
                            style={{
                                fill: 'white',
                                height: '2.4rem'
                            }}
                        />

                    </div>
                </div>



                <div className='netzero__kpi flex flex-row'>

                    <div className='netzero__kpi--card flex'>
                        <div className='netzero__kpi--left'>
                            <div className='mt-2 mr-3' style={{
                                backgroundColor: '#5D9A79'
                            }}></div>
                        </div>
                        <div className='netzero__kpi--right'>
                            <div className='text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0 flex'>{formatNumber(current)}
                                <span className='text-1xl ml-1' style={{ marginTop: 'auto' }}> kW</span>
                                
                            </div>
                            <div className='text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4'>
                                {<Trans i18nKey={'current'} >Current</Trans>}
                            </div>
                        </div>
                    </div>

                    <div className='netzero__kpi--card flex ml-10'>
                        <div className='netzero__kpi--left'>
                            <div className='mt-2 mr-3' style={{
                                backgroundColor: '#5D9A79'
                            }}></div>
                        </div>
                        <div className='netzero__kpi--right'>
                            <div className='text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0 flex'>
                                {formatNumber(today)}
                                <span className='text-1xl ml-1' style={{ marginTop: 'auto' }}> kW</span>
                            </div>
                            <div className='text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4'>
                                {<Trans i18nKey={'last2Hours'} >Last 2 hours</Trans>}
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            {produceddPower && produceddPower.length ?
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                ...LINE_CHART_OPTIONS,
                                chart: {
                                    ...LINE_CHART_OPTIONS.chart,
                                    type: 'spline',
                                    backgroundColor: 'transparent'
                                },
                                title: {
                                    text: '',
                                },
                                credits: {
                                    enabled: false
                                },
                                colors: ['#5d9a79', '#2e443e'],
                                yAxis: {
                                    labels: {
                                        enabled: true,
                                        formatter: function () {
                                            return this.axis.defaultLabelFormatter.call(this) + ' kW';
                                        },
                                        style: {
                                            color: '#C8CBCF'
                                        },
                                        x: -40,
                                        y: 15,
                                    },
                                    opposite: true,
                                    title: {
                                        text: null
                                    },
                                    gridLineColor: '#C8CBCF',
                                    gridLineDashStyle: 'Dash',
                                },
                                xAxis: {
                                    categories: getCategories(),
                                    labels: {
                                        step: 20
                                    }
                                },
                                
                                series: [
                                    {
                                        name: 'Current',
                                        data: produceddPower,
                                        lineWidth: 5
                                    },/*
                                    {
                                        name: 'Current year',
                                        data: [10, 20, 30, 54, 15, 20, 30, 45, 10, 5, 20, 30],
                                        zoneAxis: 'x'
                                    }
                                    */
                                ],

                                plotOptions: { 
                                    ...LINE_CHART_OPTIONS.plotOptions,
                                    series: {
                                        dataLabels: {
                                            enabled: false
                                        },
                                        marker: {
                                            enabled: false,
                                        }
                                    }
                                },
                            }}
                            containerProps={{
                                style: {
                                    width: '100%',
                                    height: '65%',
                                    borderRadius: 4,
                                    backgroundColor: 'transparent'
                                }
                            }}
                        />
            : ''}
             
        </div >
    )
}

export default EnergyConsumption;