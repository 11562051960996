export const calculateHeight = (suppliers, consumers) => {
    let height = 0
    if (consumers.length >= suppliers.length)
        height = 36 * consumers.length
    else
        height = 36 * suppliers.length
    height += 54
    return height
}

const calculateY1 = (tempIndex, suppliers, consumers, isSmallHeight) => {
    let chartHeight = calculateHeight(suppliers, consumers)
    let suppliersHeight = suppliers.length * 36 + 40;
    let offset = 0;
    suppliers.forEach((item, index) => { if (index < tempIndex) offset += item.width })

    return (chartHeight - suppliersHeight) / 2 + (tempIndex * 39) + offset + suppliers[tempIndex].width / 2 + 26;
}

const calculateX2 = (tempIndex, suppliers) => {
    let part = 0
    if (tempIndex < suppliers.length / 2) {
        let number = suppliers.length + 1
        part = 200 * (tempIndex + 1) / number
    }
    else if (tempIndex >= suppliers.length / 2) {
        part = suppliers[suppliers.length - tempIndex - 1].x2
    }
    else {
        part = suppliers[tempIndex].x1
    }
    return part
}

const calculateY2 = (tempIndex, suppliers, consumers, isSmallHeight) => {
    if (isSmallHeight) return 50
    let y2 = calculateHeight(suppliers, consumers) / 2 - 11
    suppliers.forEach((item, index) => {
        if (tempIndex > index) y2 += item.width
    })

    y2 += suppliers[tempIndex].width / 2
    //y2 += tempIndex
    return y2
}

export const calculateCoordinatesSuppliers = (hasBattery, hasSoldEnergy, suppliers, isSmallHeight, consumers) => {

    let tempsuppliers = [...suppliers]
    let sum = 0
    suppliers.forEach(item => {
        sum += item.valueCurrentPeriod
    })

    suppliers.forEach(element => {
        let percentage = element.valueCurrentPeriod / sum
        element.width = 36 * percentage
    });

    tempsuppliers.forEach((element, index) => {
        element.x3 = (hasBattery) ? 224 : (hasSoldEnergy) ? 209 : 224
        element.y2 = calculateY2(index, suppliers, consumers, isSmallHeight)
        element.y1 = calculateY1(index, suppliers, consumers, isSmallHeight)
        if (element.y1 == element.y2)
            element.y2 += 0.001;
        element.x1 = 25
        element.x2 = calculateX2(index, suppliers)
    })

    return tempsuppliers
}