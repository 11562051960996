import { useState, useEffect } from 'react';
import { formatNumber } from '../helpers/numeral';
import { useDispatch, useSelector } from 'react-redux';


const BuildingDetails = ({ }) => {
    const dispatch = useDispatch();
    const allSlides = useSelector(state => state.slideshow.slides);
    const lightTheme = useSelector(state => state.slideshow.lightTheme);
    const organization = useSelector(state => state.slideshow.organization);
    const organizationLogo = organization.logoFullSVG ? btoa(unescape(encodeURIComponent(organization.logoFullSVG))) : undefined;

    const [verticalSlidingTimout, setVerticalSlidingTimout] = useState(undefined);
    const [isSimpleCapacityBox, setIsSimpleCapacityBox] = useState(undefined);
    const [tempTop, setTempTop] = useState(0);
    const [capacity, setCapacity] = useState(1);


    useEffect(() => {
       
    }, []);


    return (
        <div className={'w-screen h-screen'}>
                <div className={'absolute z-10 top-0 left-0 right-0 h-2 bg-light-qualitative-magenta'} />



                <div
                    className={'w-2/3 h-full bg-dark-grayscale-1 dark:bg-dark-grayscale-6'}
                    style={{ borderRadius: 16 }}
                >

                    <div className={'buildingCharts'} >

                    <div className={'relative w-full h-full p-6 rounded-xl'} style={{ overflow: 'hidden' }} >
                        <div className="pb-2 max-h-1/6 w-full">
                            <h2
                                className="w-full text-2xl text-light-semantic-green-200 dark:text-new-green-100 uppercase ml-1"
                                style={{ letterSpacing: "0.5rem" }}
                            >
                                Hittil i år
                            </h2>
                            <div className="flex justify-between">
                                <div
                                    className="w-3/4 text-5xl  font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1"
                                    style={{ letterSpacing: "0.25rem" }}
                                >
                                    Energiproduksjon
                                </div>
                                <div className="flex">
                                    <span
                                        className="w-full text-2xl text-dark-grayscale-6 dark:text-dark-grayscale-2 uppercase mr-3 mt-1"
                                        style={{ letterSpacing: "0.25rem" }}
                                    >
                                        2023-2024
                                    </span>
                                </div>
                            </div>
                            <div className="netzero__kpi flex flex-row">
                                <div className="netzero__kpi--card flex">
                                    <div className="netzero__kpi--left">
                                        <div
                                            className="mt-2 mr-3"
                                            style={{ backgroundColor: "rgb(93, 154, 121)" }}
                                        />
                                    </div>
                                    <div className="netzero__kpi--right">
                                        <div className="text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0 flex">
                                            1 939
                                            <span className="text-1xl ml-1" style={{ marginTop: "auto" }}>
                                                {" "}
                                                kWh
                                            </span>
                                        </div>
                                        <div className="text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4">
                                            Inneværende år
                                        </div>
                                    </div>
                                </div>
                                <div className="netzero__kpi--card flex ml-10">
                                    <div className="netzero__kpi--left">
                                        <div
                                            className="mt-2 mr-3"
                                            style={{ backgroundColor: "rgb(46, 68, 62)" }}
                                        />
                                    </div>
                                    <div className="netzero__kpi--right">
                                        <div className="text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0 flex">
                                            2 898
                                            <span className="text-1xl ml-1" style={{ marginTop: "auto" }}>
                                                {" "}
                                                kWh
                                            </span>
                                        </div>
                                        <div className="text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4">
                                            Forrige år
                                        </div>
                                    </div>
                                </div>
                                <div className="netzero__kpi--card flex ml-10">
                                    <div className="netzero__kpi--left">
                                        <div className="mt-2 mr-3 down" />
                                    </div>
                                    <div className="netzero__kpi--right">
                                        <div className="text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0 flex">
                                            -33,1
                                            <span className="text-1xl ml-1" style={{ marginTop: "auto" }}>
                                                {" "}
                                                %
                                            </span>
                                        </div>
                                        <div className="text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4">
                                            Endring
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        <div className="columnCharts mt-10">
                            <div className="columnChart">
                                <div className="columnChart__header">
                                    <div className="columnChart__header--title text-right text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4">
                                        Meter name
                                    </div>
                                    <div className="columnChart__header--value text-right text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0">
                                        1 939 kWh
                                    </div>
                                </div>
                                <div className="columnChart__body">
                                    <div className="columChartLine columChartLine--current" style={{width: '80%'}}> </div>
                                    <div className="columChartLine columChartLine--compare" style={{width: '40%'}}> </div>
                                </div>
                            </div>




                            <div className="columnChart columnChart--selected">
                                <div className="columnChart__header">
                                    <div className="columnChart__header--title text-right text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4">
                                        Meter name
                                    </div>
                                    <div className="columnChart__header--value text-right text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0">
                                        1 322 kWh
                                    </div>
                                </div>
                                <div className="columnChart__body">
                                    <div className="columChartLine columChartLine--current" style={{ width: '70%' }}> </div>
                                    <div className="columChartLine columChartLine--compare" style={{ width: '35%' }}> </div>
                                </div>
                            </div>
                            <div className="columnChart">
                                <div className="columnChart__header">
                                    <div className="columnChart__header--title text-right text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4">
                                        Meter name
                                    </div>
                                    <div className="columnChart__header--value text-right text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0">
                                        1 039 kWh
                                    </div>
                                </div>
                                <div className="columnChart__body">
                                    <div className="columChartLine columChartLine--current" style={{ width: '60%' }}> </div>
                                    <div className="columChartLine columChartLine--compare" style={{ width: '30%' }}> </div>
                                </div>
                            </div>
                            <div className="columnChart">
                                <div className="columnChart__header">
                                    <div className="columnChart__header--title text-right text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4">
                                        Meter name
                                    </div>
                                    <div className="columnChart__header--value text-right text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0">
                                        939 kWh
                                    </div>
                                </div>
                                <div className="columnChart__body">
                                    <div className="columChartLine columChartLine--current" style={{ width: '55%' }}> </div>
                                    <div className="columChartLine columChartLine--compare" style={{ width: '33%' }}> </div>
                                </div>
                            </div>
                            <div className="columnChart">
                                <div className="columnChart__header">
                                    <div className="columnChart__header--title text-right text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4">
                                        Meter name
                                    </div>
                                    <div className="columnChart__header--value text-right text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0">
                                        512 kWh
                                    </div>
                                </div>
                                <div className="columnChart__body">
                                    <div className="columChartLine columChartLine--current" style={{ width: '44%' }}> </div>
                                    <div className="columChartLine columChartLine--compare" style={{ width: '10%' }}> </div>
                                </div>
                            </div>
                            <div className="columnChart">
                                <div className="columnChart__header">
                                    <div className="columnChart__header--title text-right text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4">
                                        Meter name
                                    </div>
                                    <div className="columnChart__header--value text-right text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0">
                                        132 kWh
                                    </div>
                                </div>
                                <div className="columnChart__body">
                                    <div className="columChartLine columChartLine--current" style={{ width: '20%' }}> </div>
                                    <div className="columChartLine columChartLine--compare" style={{ width: '10%' }}> </div>
                                </div>
                            </div>
                            <div className="columnChart">
                                <div className="columnChart__header">
                                    <div className="columnChart__header--title text-right text-1xl mt-0 uppercase tracking-widest  text-dark-grayscale-8 dark:text-dark-grayscale-4">
                                        Meter name
                                    </div>
                                    <div className="columnChart__header--value text-right text-3xxl font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 leading-none mb-0">
                                        39 kWh
                                    </div>
                                </div>
                                <div className="columnChart__body">
                                    <div className="columChartLine columChartLine--current" style={{ width: '10%' }}> </div>
                                    <div className="columChartLine columChartLine--compare" style={{ width: '1%' }}> </div>
                                </div>
                            </div>


                        </div>

                    </div>


                </div>

            </div>
        </div>
           
    )
}

export default BuildingDetails;