import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { formatNumber } from '../../helpers/numeral';
import BuildingIcon from '../../assets/icons/building-new.svg';
import BatteryIcon from '../../assets/icons/battery.svg';
import SolarIcon from '../../assets/icons/solar-new.svg';
import GridIcon from '../../assets/icons/grid-new.svg';
import CalendarIcon from '../../assets/icons/calendar.svg';
import CalendarDarkIcon from '../../assets/icons/calendar-dark.svg';
import {getGaugePercentage} from "../../helpers/functions";


const FlowChart = ({ fromGrid, totalConsumption, solarProduction, batteryCharge, batteryStatus, solarEnergyPercentage, solarEnergyCapacityPercentage }) => {

    let getDate = () => {
        let date = new Date();
        //let monthNames = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
        const monthNamesNo = ["januar ", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"];
        return date.getUTCDate() + '. ' + monthNamesNo[date.getMonth()].toUpperCase() + ' ' + date.getFullYear()
    }



    const [flowChanged, setFlowChanged] = useState(true);

    useEffect(() => {
        resetFlow()
        console.log(solarProduction, 'solarProduction')
    }, [solarProduction]);

    function resetFlow(){ 
        setFlowChanged(false)
        setFlowChanged(true)
    }

    function isBatteryShown(){ 
        return batteryCharge !== null && batteryStatus !== null
    }
    
    return (
        <div className={'buildingChart w-full h-full'}>
            <div className={`w-full h-full `} >
                <div className={'pb-6 max-h-1/6 w-full '}>
                    <h2 className={'w-full text-2xl text-light-semantic-green-200 dark:text-new-green-100 uppercase ml-1'} style={{letterSpacing: '.5rem'}}>
                        {<Trans i18nKey={'energyFlow.subtitle'} >Last 7 days</Trans>}
                    </h2>
                    <div className={'flex justify-between'}>
                        <div className={'w-1/2 text-5xl  font-bold text-dark-grayscale-8 dark:text-dark-grayscale-1 FamiljenGrotesk'} style={{ letterSpacing: '.25rem' }}>
                            {<Trans i18nKey={'energyFlowChart'} >Energy flow chart</Trans>}
                        </div>
                        <div className={'flex'}>
                                <span className={'w-full text-2xl text-dark-grayscale-6 dark:text-dark-grayscale-2 uppercase mr-3 mt-1'} style={{ letterSpacing: '.25rem' }}>
                                    {getDate()}
                                </span>
                                <img
                                    className={'calendar-icon'}
                                    src={CalendarIcon}
                                    alt={'icon'}
                                    style={{
                                        fill: 'white',
                                        height: '2.4rem'
                                    }}
                                />
                                <img
                                    className={'calendar-icon-dark'}
                                    src={CalendarDarkIcon}
                                    alt={'icon'}
                                    style={{
                                        fill: 'white',
                                        height: '2.4rem'
                                    }}
                                />

                        </div>
                    </div>
                </div>
                <div className={'w-full h-3/4 p-6 rounded-xl bg-dark-grayscale-1 dark:bg-dark-grayscale-6 flex mt-2'} style={{ justifyContent: 'center', height: '82%', marginTop: 0 }}>
                    

                    <div className='progress-card-1-wrapper'>
                        <div className="progress-card" >
                            <div className="percent">
                                <svg style={{opacity: 0}}>
                                    <defs>
                                        <linearGradient id="gradient-2" x1="40%" y1="40%" x2="100%" y2="100%">
                                            <stop offset="0%" stopColor="#5D9A79" />
                                            <stop offset="100%" stopColor="#5D9A7900" />
                                        </linearGradient>
                                    </defs>

                                    <circle cx="120" cy="115" r="110"></circle>
                                    <circle cx="120" cy="115" r="110" style={{ '--percent': 75 * solarEnergyCapacityPercentage }}></circle>
                                </svg>
                                <div className="number">
                                    <img
                                        className={''}
                                        src={SolarIcon}
                                        alt={'icon'}
                                        style={{
                                            fill: 'white',
                                            height: '4.5vw',
                                            marginTop: '1vh'
                                        }}
                                    />
                                    <h2 className="text-new-green-100">
                                        <span style={{ fontSize: '1.4vw', fontWeight: 600 }}>
                                            {isNaN(solarEnergyPercentage) ? 0 : solarEnergyPercentage > 100 ? 100 : solarEnergyPercentage}%
                                        </span>
                                        <br />
                                        <span className="uppercase font-normal" style={{ letterSpacing: '.25vw', marginTop: '.2vw', display: 'block' }}>
                                            <Trans i18nKey={'fromSolar'} > From solar </Trans>
                                        </span>
                                    </h2>
                                    <h3 className="text-dark-grayscale-8 dark:text-dark-grayscale-1">{formatNumber(solarProduction, 1)}<span> kW</span></h3>
                                    <div className="title">
                                        <h2>{<Trans i18nKey={'solar.solar'} >Solar</Trans>}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="progress-card">
                            <div className="percent">
                                <svg style={{ opacity: 0 }}>
                                    <defs>
                                        <linearGradient id="gradient-3" x1="40%" y1="40%" x2="100%" y2="100%">
                                            <stop offset="0%" stopColor="#5D9A79" />
                                            <stop offset="100%" stopColor="#5D9A7900" />
                                        </linearGradient>
                                    </defs>

                                    <circle cx="120" cy="115" r="110"></circle>
                                    <circle cx="120" cy="115" r="110" style={{ '--percent': 65 }}></circle>
                                </svg>
                                <div className="number">
                                    <img
                                        className={''}
                                        src={GridIcon}
                                        alt={'icon'}
                                        style={{
                                            fill: 'white',
                                            height: '4.5vw',
                                            marginTop: '1vh',
                                            mmarginBottom: '2vw'
                                        }}
                                    />
                                    <h2 className="text-new-green-100">
                                        <span style={{ fontSize: '1.4vw', fontWeight: 600 }}>
                                            {isNaN(solarEnergyPercentage) ? 100 : solarEnergyPercentage > 100 ? 0 : 100 - solarEnergyPercentage}%
                                        </span>
                                        <br />
                                        <span className="uppercase font-normal" style={{ letterSpacing: '.25vw', marginTop: '.2vw', display: 'block' }}>
                                            <Trans i18nKey={'fromGrid'} > From grid </Trans>
                                        </span>
                                    </h2>
                                    <h3 className="text-dark-grayscale-8 dark:text-dark-grayscale-1">{formatNumber(fromGrid, 1)}<span> kW</span></h3>
                                    <div className="title " >
                                        <h2>{<Trans i18nKey={'solar.grid'} >Grid</Trans>}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     
                    <div className="flow-animation">
                        {flowChanged ? 
                            <svg width="360" height="200" viewBox="0 0 360 200" >

                                {   !isBatteryShown() &&
                                    <>
                                        <line x1="10" y1="100" x2="180" y2="100" strokeLinecap="round" strokeDasharray="0.2, 15" strokeWidth="6" className='green-dots' />
                                        <line x1="10" y1="100" x2="180" y2="100" strokeLinecap="round" strokeDasharray="0.2, 15" strokeWidth="6" className={totalConsumption ? "flow__1 gray-dots" : 'gray-dots'} />
                                    </>
                                }

                                {
                                    isBatteryShown() &&
                                    <>
                                        <line x1="190" y1="100" x2="20" y2="0" strokeLinecap="round" strokeDasharray="0.2, 15" strokeWidth="6" className='green-dots' />
                                        <line x1="190" y1="100" x2="20" y2="0" strokeLinecap="round" strokeDasharray="0.2, 15" strokeWidth="6" className={totalConsumption ? 'flow__4 gray-dots' : "flow__4 gray-dots"} />
                                        <line x1="190" y1="100" x2="20" y2="200" strokeLinecap="round" strokeDasharray="0.2, 15" strokeWidth="6" className='green-dots' />
                                        <line x1="190" y1="100" x2="20" y2="200" strokeLinecap="round" strokeDasharray="0.2, 15" strokeWidth="6" className={batteryCharge > 0 ? ' gray-dots flow__5--batterytogrid' : "flow__5--gridtobattery gray-dots"} />
                                    </>
                                }
                                
                                <line x1="190" y1="100" x2="350" y2="0" strokeLinecap="round" strokeDasharray="0.2, 15" strokeWidth="6" className='green-dots' />
                                <line x1="190" y1="100" x2="350" y2="0" strokeLinecap="round" strokeDasharray="0.2, 15" strokeWidth="6" className={solarProduction < 0.1 ?'flow__2 gray-dots flow__2--nosolar' : "flow__2 gray-dots"} />
                                <line x1="190" y1="100" x2="350" y2="200" strokeLinecap="round" strokeDasharray="0.2, 15" strokeWidth="6" className='green-dots' />

                                <line x1="190" y1="100" x2="350" y2="200" strokeLinecap="round" strokeDasharray="0.2, 15" strokeWidth="6" className={fromGrid === 0 && solarProduction < 0.1 ? 'gray-dots' : solarProduction >= totalConsumption ? "flow__3 gray-dots flow__3--solartogrid" : "flow__3 gray-dots" } />
                                <line x1="190" y1="100" x2="350" y2="200" strokeLinecap="round" strokeDasharray="0.2, 15" strokeWidth="6" className="flow__3 gray-dots" />
                            </svg>
                            :
                            ''
                        }
                    </div>


                    <div className="progress-card-1-wrapper" style={{marginTop: '2vw'}}>
                        <div className="progress-card" >
                            <div className="percent">
                                <svg>
                                    <defs>
                                        <linearGradient id="gradient-1" x1="40%" y1="40%" x2="100%" y2="100%">
                                            <stop offset="0%" stopColor="#2ecc71" />
                                            <stop offset="100%" stopColor="#5D9A7920" />
                                        </linearGradient>
                                    </defs>

                                    <circle cx="120" cy="115" r="110"></circle>
                                    <circle cx="120" cy="115" r="110" style={{ '--percent': getGaugePercentage(isNaN(solarEnergyPercentage) ? 0 : solarEnergyPercentage) }}></circle>
                                </svg>
                                <div className="number">
                                    <img
                                        className={''}
                                        src={BuildingIcon}
                                        alt={'icon'}
                                        style={{
                                            fill: 'white',
                                            height: '4.5vw',
                                            marginTop: '1vh'
                                        }}
                                    />
                                    
                                    <h3 className="text-dark-grayscale-8 dark:text-dark-grayscale-1" style={{ marginTop: '2.5vw' }}>{formatNumber(batteryCharge ? totalConsumption + batteryCharge : totalConsumption, 1)}<span> kW</span></h3>
                                    <div className="title">
                                        <h2  >{<Trans i18nKey={'building'} >Building</Trans>}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            isBatteryShown() &&
                            <div className="progress-card" >
                                <div className="percent">
                                    <svg>
                                        <defs>
                                            <linearGradient id="gradient-b" x1="40%" y1="40%" x2="100%" y2="100%">
                                                <stop offset="0%" stopColor="#3fb68e" />
                                                <stop offset="100%" stopColor="#3fb68e00" />
                                            </linearGradient>
                                        </defs>

                                        <circle cx="120" cy="115" r="110"></circle>
                                        <circle cx="120" cy="115" r="110" style={{ '--percent': getGaugePercentage(batteryStatus*100) }}></circle>
                                    </svg>
                                    <div className="number" style={{marginTop: '-2vw'}}>
                                        <img
                                            className={''}
                                            src={BatteryIcon}
                                            alt={'icon'}
                                            style={{
                                                fill: 'white',
                                                height: '4.5vw',
                                                marginTop: '1vh'
                                            }}
                                        />
                                        <h2 className="text-new-green-100" style={{display: 'none'}}>
                                            <span style={{ fontSize: '1.4vw', fontWeight: 600 }}>
                                                {formatNumber(batteryStatus*100, 1)}%
                                            </span>
                                             <br />
                                                <span className="uppercase font-normal" style={{ letterSpacing: '.25vw', marginTop: '.2vw', display: 'block' }}>
                                                    <Trans i18nKey={'charged'} > Charged </Trans>
                                                </span>
                                        </h2>
                                        <h3 className="text-dark-grayscale-8 dark:text-dark-grayscale-1" style={{ marginTop: '2.5vw' }}>{formatNumber(batteryCharge, 1)}<span> kW</span></h3>
                                        <div className="title" >
                                                <h2 style={{ marginBottom: 0 }} >{<Trans i18nKey={'battery'} >Battery</Trans>}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default FlowChart;