import React from "react";
import { formatNumber } from "../helpers/numeralFormatter";
import { Trans } from 'react-i18next';

const Rect = ({ startX, startY, suppliersSum, producedSum, consumersSum, soldSum, isLightTheme }) => {
  return <g>
           <g>
            <rect x={startX} y={startY - 20} rx={5} width={132} height={84} fill={isLightTheme ? "#f9fafa" : '#3a4552'} stroke={isLightTheme ? "#ef8d5d" : '#2a3542'} strokeWidth=".5" />
            <rect x={startX+4} y={startY-16} rx={2} width={60} height={36} fill="#ef8d5d" /> 
            <text id="purchased-title" x={startX + 7} y={startY - 1} fill="#FFFFFF" fontSize="7">{<Trans i18nKey={'common:purchased'} >Purchased</Trans>}</text>
            <text id="purchased-sum" x={startX + 7} y={startY + 9} fontWeight="bold" fill="#FFFFFF" fontSize="8.5">{formatNumber(suppliersSum) + ' kWh'}</text>
            <rect x={startX+4} y={startY+24} rx={2} width={60} height={36} fill="#3fb68e" />
            <text id="produced-title" x={startX + 7} y={startY + 38} fill="#FFFFFF" fontSize="7">{<Trans i18nKey={'common:produced'} >Produced</Trans>}</text>
            <text id="produced-sum" x={startX + 7} y={startY + 48} fontWeight="bold" fill="#FFFFFF" fontSize="8.5">{formatNumber(producedSum) + ' kWh'}</text>
            <rect x={startX+68} y={startY-16} rx={2} width={60} height={36} fill="#f06a93" />
            <text id="consumed-title" x={startX + 71} y={startY - 1} fill="#FFFFFF" fontSize="7">{<Trans i18nKey={'common:consumed'} >Consumed</Trans>}</text>
            <text id="consumed-sum" x={startX + 71} y={startY + 9} fontWeight="bold" fill="#FFFFFF" fontSize="8.5">{formatNumber(consumersSum) + ' kWh'}</text>
            <rect x={startX+68} y={startY+24} rx={2} width={60} height={36} fill="#74abe2" />
            <text id="sold-title" x={startX + 71} y={startY + 38} fill="#FFFFFF" fontSize="7">{<Trans i18nKey={'common:sold'} >Sold</Trans>}</text>
            <text id="sold-sum" x={startX + 71} y={startY + 48} fontWeight="bold" fill="#FFFFFF" fontSize="8.5">{ formatNumber(soldSum) + ' kWh'}</text>
          </g>
        </g>
};

export default Rect;