//Redux
import { createStore } from 'redux';

//Reducers
import rootReducer from './reducers/rootReducer';

const configureStore = createStore(rootReducer);


export default configureStore;
