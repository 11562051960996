import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import Logo from '../../assets/images/em_logo_all_white.svg';
import TemperatureCard from './cards/TemperatureCard';
import Card from './cards/Card';
import HumidityCard from './cards/HumidityCard';
import Co2Card from './cards/Co2Card';
import MotionCard from './cards/MotionCard';
import LightCard from './cards/LightCard';


const Tag = () => {
    const location = useLocation();
    const params = useParams();
    const [tagResponse, setTagResponse] = useState(undefined);
    const [temperatureData, setTemperatureData] = useState(undefined);
    const [humidityData, setHumidityData] = useState(undefined);
    const [co2Data, setCo2Data] = useState(undefined);
    const [batteryData, setBatteryData] = useState(undefined);
    const [motionData, setMotionData] = useState(undefined);
    const [lightData, setLightData] = useState(undefined);
    const [otherData, setOtherData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    function fetchData() {
        let url = `https://em-public-api.azurewebsites.net/v1/Measurement/public/${params.id}/?code=${location.search.split('=')[1]}=`;
        fetch(url)
            .then(response => response.json())
            .then(data => { console.log(data); setTagResponse(data); setIsLoading(false) });
    }

    useEffect(() => {
        if(params)
            fetchData()
    }, [params]);

    useEffect(() => {
        if (tagResponse){
            setTemperatureData(tagResponse.find(x => x.type === 'TEMPERATURE'))
            setHumidityData(tagResponse.find(x => x.type === 'HUMIDITY'))
            setCo2Data(tagResponse.find(x => x.type === 'CO2'))
            setBatteryData(tagResponse.find(x => x.type === 'BATTERY'))
            setMotionData(tagResponse.find(x => x.type === 'MOTION'))
            setLightData(tagResponse.find(x => x.type === 'LIGHT'))
            if (!temperatureData && !humidityData && !co2Data && !batteryData && !motionData && !lightData){
                setOtherData(tagResponse[0])
            }
        }
    }, [tagResponse]);


    return (
        <div>
            <div className="tag__header">
                <img src={Logo} alt={'logo'} ></img>
            </div>
            <div className="tag__body">
                <div className="tag__body--header">
                    <h2><Trans i18nKey={'tag.tagData'} >Tag data</Trans></h2>
                    <h3>ID: {params && params.id}</h3>
                    {batteryData && batteryData.data && batteryData.data[0] && <h4><b><Trans i18nKey={'tag.batteryLevel'} >Battery level</Trans>:</b> {batteryData.data[0].value * 100}%</h4> }
                </div>
                {isLoading ? <div className='loader-wrapper'><div class="loader"><Trans i18nKey={'common:loading'} >Loading</Trans>...</div></div> :
                <div className="tag__charts">
                    {otherData && <Card data={otherData}></Card>}
                    {temperatureData && <TemperatureCard data={temperatureData}></TemperatureCard>}
                    {co2Data && <Co2Card data={co2Data}></Co2Card>}
                    {humidityData && <HumidityCard data={humidityData}></HumidityCard>}
                    {motionData && <MotionCard data={motionData}></MotionCard>}
                    {lightData && <LightCard data={lightData}></LightCard>}
                </div>}
            </div>
        </div>
    )
}

export default Tag;