export const calculateHeight = (suppliers, consumers) => {
    let height = 0
    if (consumers.length >= suppliers.length)
        height = 36 * consumers.length
    else
        height = 36 * suppliers.length
    height += 54
    return height
}

const calculateY1 = (tempIndex, consumers, suppliers) => {
    let chartHeight = calculateHeight(suppliers, consumers)
    let consumersHeight = consumers.length * 39 + 40;
    let offset = 0;
    consumers.forEach((item, index) => { if (index < tempIndex) offset += item.width })

    return (chartHeight - consumersHeight) / 2 + (tempIndex * 39) + offset + consumers[tempIndex].width / 2 + 28;
}

const calculateX2 = (tempIndex, consumers) => {
    let part = 0

    if (tempIndex < consumers.length / 2) {
        let number = consumers.length + 1
        part = 225 * (tempIndex + 1) / number
        part += 280
    } else if (tempIndex >= consumers.length / 2) {
        part = consumers[consumers.length - tempIndex - 1].x2
    }

    return part
}

const calculateY2 = (tempIndex, consumers, suppliers, isSmallHeight) => {
    if (isSmallHeight) return 50
    let y2 = calculateHeight(suppliers, consumers) / 2 - 11
    consumers.forEach((item, index) => {
        if (tempIndex > index) y2 += item.width
    })

    y2 += consumers[tempIndex].width / 2
    //y2 += tempIndex

    return y2
}

export const calculateCoordinatesConsumers = (hasBattery, hasSoldEnergy, soldEnergy, consumers, isSmallHeight, suppliers) => {

    let tempConsumers = [...consumers]
    let sum = 0
    consumers.forEach(item => {
        sum += item.valueCurrentPeriod
    })
    if (hasSoldEnergy) {
        sum -= soldEnergy.valueCurrentPeriod
    }
    consumers.forEach(element => {
        let percentage = element.valueCurrentPeriod / sum
        element.width = 36 * percentage
        if (element.width < 1) element.width = 1
    });

    tempConsumers.forEach((element, index) => {
        element.x3 = 470
        element.y2 = calculateY1(index, consumers, suppliers)
        element.y1 = calculateY2(index, consumers, suppliers)
        if (element.y1 == element.y2)
            element.y2 += 0.001;
        element.x1 = 300
        element.x2 = calculateX2(index, consumers)
    })
    return tempConsumers
}