import ReactPlayer from 'react-player'


const ViedoSlide = ({ slide, tempIndex, slideIndex, onVideoEnd }) => {

    const opts = {
        height: '1080',
        width: '1920',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            controls: 0,
            showinfo: 0,
            mute: 1,
        },
    }

    const stateChange = (event) => {
        if(event.data === 0){
            onVideoEnd();
        }
    }

    return (
        <div className={'w-screen h-screen'}>
            <div className={'absolute z-10 w-screen h-screen'} style={{background: '#000'}}> 
                {(  
                    tempIndex === slideIndex &&
                    slide &&
                    slide.slideVideo &&
                    slide.slideVideo.url
                )
                    ?
                    /*
                    <YouTube 
                        videoId={getYoutubeUrl(slide.slideVideo.url)}
                        opts={opts} 
                        onStateChange={stateChange}
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                    />
                    */
                    <ReactPlayer url={slide.slideVideo.url}
                        width="100%"
                        height="100%"
                        playing={true}
                        controls={false}
                        onEnded={onVideoEnd}
                         />
                    : false
                }
            </div>
        </div>
    )
}


const getYoutubeUrl = (url) => {
    //let str = 'https://www.youtube.com/embed/' + url.split('/')[3] + '?autoplay=1&controls=0&showinfo=0' + '&mute=1'
    let str = url.split('/')[3] //+ '?autoplay=1&controls=0&showinfo=0' + '&mute=1'
    if(str.includes('watch?v=')){
        str = str.replace('watch?v=', '')
    }
    return str
}

export default ViedoSlide;