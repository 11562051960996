import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import 'moment/locale/en-gb';
import 'moment/locale/nb';
import { formatNumber } from '../helpers/numeral';
import Logo from '../assets/images/em_logo_all_purple_twolines.svg';
import Slogan from '../assets/images/slogan.svg';
import SunriseIcon from '../assets/icons/sunrise.svg';
import SunsetIcon from '../assets/icons/sunset.svg';
import SunnyDayIcon from '../assets/icons/sunny-day.svg';
import WindUpIcon from '../assets/icons/wind-up.svg';
import UmbrellaIcon from '../assets/icons/umbrella.svg';
import HumidityIcon from '../assets/icons/humidity.svg';
import ClockIcon from '../assets/icons/clock.png';
import WeatherCard from '../components/cards/WeatherCard';
import SmallWeatherCard from '../components/cards/SmallWeatherCard';
import SlideBackground from '../assets/images/weather-background.jpg';


const WeatherSlide = ({ slide }) => {
    const [date, setDate] = useState('');
    const [sunrise, setSunrise] = useState('');
    const [sunset, setSunset] = useState('');
    const [nextForecast, setNextForecast] = useState(null);
    const [forecast, setForecast] = useState([]);
    const [credit, setCredit] = useState('');
    const [place, setPlace] = useState('');

    useEffect(() => {
        setDate(moment().tz('Europe/Oslo').locale('nb').format('LL'));
        if (slide) {
            if (
                slide.slideWeather &&
                slide.slideWeather.forecast &&
                slide.slideWeather.forecast.length > 0 &&
                slide.slideWeather.forecast[0]
            ) {
                setNextForecast(slide.slideWeather.forecast[0]);
                setForecast(slide.slideWeather.forecast);
                setCredit(slide.slideWeather.credit)
                setPlace(slide.slideWeather.place)
            }

            if (
                slide.slideWeather &&
                slide.slideWeather.sunrise &&
                slide.slideWeather.sunset
            ) {
                setSunrise(moment(slide.slideWeather.sunrise).tz('Europe/Oslo').format('HH:mm'))
                setSunset(moment(slide.slideWeather.sunset).tz('Europe/Oslo').format('HH:mm'));
            }
        }
    }, [slide]);

    return (
        <div className={'w-screen h-screen'}>
            <div className={'absolute z-20 top-0 left-0 right-0 h-2 bg-em-purple-500'} />
            <img
                className={'absolute z-0 w-screen h-screen object-cover'}
                src={SlideBackground}
                alt={'Background'}
            />
            <div className={'absolute z-10 w-screen h-screen bg-em-blue-transparent-90 p-16 pt-8'}>
                <div className={'flex w-full justify-between'}>
                    <div className={'w-2/5'}>
                        <h1 className={'text-7xl text-em-white-500'}>
                            {place}
                        </h1>
                        <p className={'text-4xl text-em-white-500 mt-9'}>
                            {date}
                        </p>
                        <div className={'flex space-x-3 h-12 w-full justify-start items-center'}>
                            <img
                                className={'h-8'}
                                src={ClockIcon}
                                alt={'Clock'}
                            />
                            <p className={'text-4xl text-em-white-500'}>
                                {nextForecast ? moment(nextForecast.utcTime).tz('Europe/Oslo').format('HH:mm') : ''} - {nextForecast ? moment(nextForecast.utcTime).add(1, 'hours').tz('Europe/Oslo').format('HH:mm') : ''}
                            </p>
                        </div>
                        <div
                            className={'flex h-32 mt-4'}
                            style={{
                                width: '180%'
                            }}
                        >
                            <img
                                className={'h-full'}
                                src={nextForecast ? `/weather/${nextForecast.symbol}.svg` : SunnyDayIcon}
                                alt={'Weather'}
                            />
                            <h1 className={'text-9xl text-em-white-500 ml-7 font-bold'}>
                                {nextForecast ? formatNumber(nextForecast.temperature, 1) : ''} <span className={'text-7xl font-normal'}>&#176;C</span>
                            </h1>
                        </div>
                    </div>

                    <div className={'flex flex-col justify-center items-end w-2/5'}>
                        <div className={'flex justify-end items-center h-12 mt-4'}>
                            <img
                                src={SunriseIcon}
                                alt={'Sunrise'}
                            />
                            <h1 className={'text-3xl text-em-white-500 ml-3'}>
                                {sunrise}
                            </h1>
                            <img
                                className={'ml-9'}
                                src={SunsetIcon}
                                alt={'Sunset'}
                            />
                            <h1 className={'text-3xl text-em-white-500 ml-3'}>
                                {sunset}
                            </h1>
                        </div>
                        <div className={'flex w-screen justify-end space-x-5 mt-16'}>
                            <SmallWeatherCard
                                icon={WindUpIcon}
                                iconAlt={'Wind speed'}
                                value={nextForecast ? nextForecast.windSpeed : ''}
                                windDirection={nextForecast ? nextForecast.windDirection : 0}
                                unit={'m/s'}
                            />
                            <SmallWeatherCard
                                icon={UmbrellaIcon}
                                iconAlt={'Precipitation'}
                                value={nextForecast ? nextForecast.precipitation : ''}
                                unit={'mm'}
                            />
                            <SmallWeatherCard
                                icon={HumidityIcon}
                                iconAlt={'Humidity'}
                                value={nextForecast ? nextForecast.humidity : '0'}
                                unit={'%'}
                            />
                        </div>
                    </div>
                </div>

                <div className={'flex justify-end space-x-5 h-2/6 mt-24'}>
                    {forecast.length > 0
                        ? forecast.slice(1, 8).map((weather, index) => (
                            <WeatherCard
                                key={`weather_card_${index}`}
                                time={moment(weather.utcTime).tz('Europe/Oslo').format('HH:mm')}
                                icon={weather.symbol}
                                temperature={weather.temperature}
                                windSpeed={weather.windSpeed}
                                windDirection={weather.windDirection}
                            />
                        ))
                        : false
                    }
                </div>

                <div className={'absolute bottom-8 flex justify-between items-end h-24 p-4  space-x-4  bg-em-white-500 p-4 px-6 h-8vh'}
                    style={{
                        borderRadius: 16
                    }}
                >
                    <div class="slogan__animation" >
                        <div>
                            <img
                                class="slogan__animation__logo"
                                src={Logo}
                                alt={"EmLogoWhite"}
                                style={{ height: "4.5vh" }}
                            />
                            <img
                                className="slogan__animation__slogan"
                                src={Slogan}
                                alt={"SloganWhite"}
                                style={{ height: "4.5vh" }}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={`absolute flex items-end bottom-0 right-0  p-4 px-6`}
                >
                    <h5 className={'text-em-white-500'}>{credit}</h5>
                </div>
            </div>
        </div>
    )
}

export default WeatherSlide;